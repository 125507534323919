import React, { useState } from 'react';
import Thanks from '../components/thanksContact'
import axios from 'axios'
import Footer from "../components/footer/footer";
import { motion } from "framer-motion";
import Scroll from "../components/locomotiveScroll";
import { fade } from "../helpers/transitionHelper";
import { injectIntl } from "gatsby-plugin-intl-v3";
import SEO from "../components/seo";

const ContactPage = ({ intl, location }) => {
  const [formState, updateForm] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const updateInput = event => {
    updateForm({ ...formState, [event.target.name]: event.target.value });
  };

  const submitContact = (e) => {
    e.preventDefault();
    const { name,lastName, email, phone, message } = formState;
    axios
        .post("https://4mmtd9cv4i.execute-api.us-east-1.amazonaws.com/live/contact",
            {body: { name,lastName, email, phone, message }})
        .then((res) => {
          console.log(res)
        }).then(() => {
      updateForm({
        message: intl.formatMessage({ id: "thxForm" }),
        email: '',
        name: '',
        lastName:'',
        phone: ''
      });
      setHasSubmitted(true);
    }).catch(err => {
      console.log('Error in post request by axios',err);
    })
  };

  return (
    <>
      <Scroll callback={location} />

      <SEO
          title={intl.formatMessage({ id: "titleContact" })}
      />
      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div className="overflow-hidden grid md:grid-cols-4 pt-16 md:pt-16 xl:pt-20">
          <div
            className="absolute top-0 left-0 right-0 bottom-0"
            id="pinned-pane"
          />
          <motion.div
            variants={fade}
            className="bg-offwhite border-b md:border-b-0 md:border-r border-black md:col-span-1 md:h-screen relative px-4 pt-5 pb-10 md:py-24 md:mt-2 xl:mt-5 overflow-hidden md:mb-0"
            data-scroll-sticky
            data-scroll
            data-scroll-target="#pinned-pane"
          >
            <h1 className="mb-0 pb-0 mt-12 md:mt-0 xl:mt-0 relative about-title md:w-11/12 xl:w-full xl:pr-0 textreveal">
              {intl.formatMessage({ id: "titleContact" })}
            </h1>
            <div className="scrollreveal mt-6 md:mt-20">
              <span className="font-bold block textreveal">
                {intl.formatMessage({ id: "addressForm" })}
              </span>
              <span className="mb-3 md:mb-4 block textreveal">
                Veko Giz Plaza 3/79 Maslak
                <br />
                Istanbul TR
                <br />
                34398
              </span>

              <a
                href="mailto:info@academyfestivalistanbul.com"
                className="block group textreveal"
              >
                <span className="block overflow-hidden relative h-auto md:h-5 xl:h-5 md:my-3px">
                  <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                    <span className="font-bold block transform translate">
                      {intl.formatMessage({ id: "mailForm" })}
                    </span>
                    <a
                      href="mailto:info@academyfestivalistanbul.com"
                      className="hidden md:block"
                    >
                      info@academyfestivalistanbul.com
                    </a>
                    <a
                      href="mailto:info@academyfestivalistanbul.com"
                      className="md:hidden block mb-3 md:mb-0"
                    >
                      info@academyfestivalistanbul.com
                    </a>
                  </span>
                </span>
              </a>
            </div>
          </motion.div>

          <motion.div variants={fade} className="md:col-span-3 relative">
            <div className="content relative" id="content-pane">
              <div className="py-4 px-4 md:py-0 md:px-16 xl:px-20 relative z-10 flex flex-wrap items-center h-full">
                <div className="w-full h-full">


                  {!hasSubmitted ? (
                  <form
                      data-scroll
                      className="mt-6 md:mt-40 block w-full md:w-9/12"
                      id="contactForm"
                      name="Contact"
                      method='POST'
                      data-cy='contactForm'
                      onSubmit={submitContact}
                  >
                    <input type='hidden' name='form-name' value="Contact" />
                    <span className="overflow-hidden block relative mb-3 md:mb-5">
                      <motion.span
                        initial={{ translateX: "-100%" }}
                        animate={{ translateX: 0 }}
                        transition={{
                          duration: 1,
                          delay: 0.2,
                          ease: [0.76, 0, 0.24, 1],
                        }}
                        className="block w-full h-px bg-black absolute bottom-0 left-0"
                      />
                      <input
                        type="text"
                        value={formState.name}
                        onChange={updateInput}
                        name='name'
                        className="bg-offwhitelight focus:bg-offwhite font-sans py-3 border-black text-sm md:text-lg xl:text-xl focus:outline-none hover:outline-none w-full block"
                        placeholder={intl.formatMessage({ id: "firstName" })}
                        required
                      />
                    </span>
                    <span className="overflow-hidden block relative mb-3 md:mb-5">
                      <motion.span
                        initial={{ translateX: "-100%" }}
                        animate={{ translateX: 0 }}
                        transition={{
                          duration: 1,
                          delay: 0.2,
                          ease: [0.76, 0, 0.24, 1],
                        }}
                        className="block w-full h-px bg-black absolute bottom-0 left-0"
                      />
                      <input
                        type="text"
                        value={formState.lastName}
                        name="lastName"
                        onChange={updateInput}
                        className="bg-offwhitelight focus:bg-offwhite font-sans py-3 border-black text-sm md:text-lg xl:text-xl focus:outline-none hover:outline-none w-full block"
                        placeholder={intl.formatMessage({ id: "lastName" })}
                        required
                      />
                    </span>
                    <span className="overflow-hidden block relative mb-3 md:mb-5">
                      <motion.span
                        initial={{ translateX: "-100%" }}
                        animate={{ translateX: 0 }}
                        transition={{
                          duration: 1,
                          delay: 0.25,
                          ease: [0.76, 0, 0.24, 1],
                        }}
                        className="block w-full h-px bg-black absolute bottom-0 left-0"
                      />
                      <input
                          value={formState.email}
                          onChange={updateInput}
                          type='email'
                          name='email'
                        className="bg-offwhitelight focus:bg-offwhite font-sans py-3 border-black text-sm md:text-lg xl:text-xl focus:outline-none hover:outline-none w-full block"
                        placeholder={intl.formatMessage({ id: "email" })}
                        required
                      />
                    </span>
                    <span className="overflow-hidden block relative mb-3 md:mb-5">
                      <motion.span
                        initial={{ translateX: "-100%" }}
                        animate={{ translateX: 0 }}
                        transition={{
                          duration: 1,
                          delay: 0.3,
                          ease: [0.76, 0, 0.24, 1],
                        }}
                        className="block w-full h-px bg-black absolute bottom-0 left-0"
                      />
                      <input
                          value={formState.phone}
                          onChange={updateInput}
                          type='tel'
                          name='phone'
                        className="bg-offwhitelight focus:bg-offwhite font-sans py-3 border-black text-sm md:text-lg xl:text-xl focus:outline-none hover:outline-none w-full block"
                        placeholder={intl.formatMessage({ id: "phone" })}
                        required
                      />
                    </span>
                    <span className="overflow-hidden block relative mb-6 md:mb-12">
                      <motion.span
                        initial={{ translateX: "-100%" }}
                        animate={{ translateX: 0 }}
                        transition={{
                          duration: 1,
                          delay: 0.35,
                          ease: [0.76, 0, 0.24, 1],
                        }}
                        className="block w-full bg-black absolute bottom-0 left-0"
                      />
                      <textarea
                        rows="4"
                        value={formState.content}
                        name='message'
                        onChange={updateInput}
                        className="bg-offwhitelight focus:bg-offwhite font-sans py-3 border-black text-sm md:text-lg xl:text-xl focus:outline-none hover:outline-none w-full block"
                        placeholder={intl.formatMessage({ id: "message" })}
                        required
                      />
                    </span>

                    <span className="overflow-hidden block relative">
                      <motion.span
                        initial={{ translateY: "100%" }}
                        animate={{ translateY: 0 }}
                        transition={{
                          duration: 1,
                          delay: 0.65,
                          ease: [0.76, 0, 0.24, 1],
                        }}
                        className="block"
                      >
                        <button
                          type="submit"
                          className="mx-auto my-6 md:my-20 block text-base font-medium uppercase border-b border-black focus:outline-none hover:outline-none text-lg md:text-xl xl:text-2xl group"
                        >
                          <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                            <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                              <span className="block transform translate">
                                {intl.formatMessage({ id: "send" })}
                              </span>
                              <span className="hidden md:block">
                                {intl.formatMessage({ id: "send" })}
                              </span>
                            </span>
                          </span>
                        </button>
                      </motion.span>
                    </span>
                  </form>
                  ) : (
                      <Thanks message={intl.formatMessage({ id: "thx" })} />
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="-mt-5 relative z-50">
            <Footer hideCta color="white" />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default injectIntl(ContactPage);
