import React from "react";
import Footer from "../components/footer/footer";
import { graphql } from "gatsby";
import { injectIntl, Link } from "gatsby-plugin-intl-v3";
import { motion } from "framer-motion";
import Scroll from "../components/locomotiveScroll";
import { fade } from "../helpers/transitionHelper";
import Carousel from "../components/carousel";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import {GatsbyImage, getSrc} from "gatsby-plugin-image";
import SEO from "../components/seo";

const ManagementPage = ({ location, intl, data }) => {
  const { name, about, title, image, email } = data.contentfulManagement;

  return (
    <>
      <Scroll callback={location} />
      <SEO
          title={`${name} – ${title}`}
          image={getSrc(image)}
      />
      <motion.section
        initial="initial"
        className="bg-offwhitelight overflow-hidden"
        animate="enter"
        exit="exit"
      >
        <div data-scroll>
          <div className="bg-offwhitelight border-b border-offblack">
            <div className="relative mt-20 md:mt-8 lg:mt-12">
              <motion.div
                variants={fade}
                className="w-full md:px-20 px-4 py-6 md:py-10 xl:py-12"
              >
                <div className="flex flex-col justify-center items-center w-full h-full">
                  <div className="md:mb-6 md:mt-16 w-full md:w-8/12 h-84 md:h-carouselM flex flex-col">
                    <div className="w-full h-full box-border border-black border-2 md:border-8 bg-offwhite">
                      <figure className="w-full h-full relative text-center">
                        <GatsbyImage
                          image={image.gatsbyImageData}
                          alt={image.title}
                          objectFit="cover"
                          objectPosition="center"
                          className=" h-full w-full max-w-full overflow-hidden"
                        />
                        {image.description && (
                          <figcaption className="text-xs md:text-lg z-50 absolute bottom-0 left-0 w-full text-center p-2 md:p-3 mix-blend-difference text-offwhite">
                            {image.description}
                          </figcaption>
                        )}
                      </figure>
                    </div>
                  </div>

                  <div className="mx-auto text-center mt-4">
                    <Link
                      to="/festival/management/"
                      className="text-base md:text-lg xl:text-xl uppercase leading-none font-medium block self-start text-left pl-2 group"
                    >
                      <span className="inline-block transform rotate-180 leading-none align-top mr-1">
                        ↳
                      </span>
                      <span className="inline-block overflow-hidden relative h-auto md:h-5 xl:h-5 leading-tight align-top">
                        <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                          <span className="block transform translate">
                            {intl.formatMessage({ id: "backButton" })}
                          </span>
                          <span className="hidden md:block">
                            {intl.formatMessage({ id: "backButton" })}
                          </span>
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          <motion.div variants={fade} className="w-full z-30 pt-0">
            <div className="pb-16 md:pb-24 relative flex flex-wrap w-full">
              <div className="px-4 md:px-0 w-full md:mx-60 pt-10">
                <h1 className="font-bold mx-3 md:mx-0 w-full project-title mb-8 md:mb-12 block textreveal">
                  {name} <br />
                  <br />
                  <span className="italic font-normal">{title}</span>
                  <br />
                  <a href={`mailto:${email}`} className=" text-sm md:text-lg font-light">{email}</a>
                </h1>

                <div className="content text-justify w-full">
                  {renderRichText(about)}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="-mt-5 relative z-50">
            <Footer hideCta color="white" />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export const query = graphql`
  query ContentFulManagementPages($slug: String, $language: String) {
    contentfulManagement(slug: { eq: $slug }, node_locale: { eq: $language }) {
      name
      title
      slug
      email
      about {
        raw
      }
      image {
        title
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default injectIntl(ManagementPage);
