import React from "react";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl-v3";

const languageName = {
  en: "EN",
  tr: "TR",
};

const Language = () => {
  return (
    <div className="h-8 border-black border-t-10 w-full bg-offwhite fixed top-0 left-0 right-0 z-60 texture-overlay flex w-full flex-row items-center justify-center">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map((language) => (
            <div className="lang" key={language}>
              <button
                className={
                  "text-xs md:text-sm cursor-pointer inline-block" +
                  (currentLocale === language
                    ? `text-xs md:text-sm lang_active relative font-bold`
                    : `lang_inactive`)
                }
                onClick={() => changeLocale(language)}
              >
                <span className="text-black hover:text-offblack focus:text-offblack transition duration-500 ease-in-out">
                  {languageName[language]}
                </span>
              </button>
              <span className="mx-2 align-baseline separator">
                <span className="text-xs md:text-sm text-black">|</span>
              </span>
            </div>
          ))
        }
      </IntlContextConsumer>
    </div>
  );
};

export default Language;
