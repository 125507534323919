import React from "react";
import Footer from "../../components/footer/footer";
import { graphql } from "gatsby";
import { motion } from "framer-motion";
import Scroll from "../../components/locomotiveScroll";
import { fade } from "../../helpers/transitionHelper";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import { injectIntl, Link } from "gatsby-plugin-intl-v3";
import SEO from "../../components/seo";

const FestivalManagement = ({ intl, location, data }) => {
  const color = "white";
  let colorClasses = `bg-black text-offwhite `;
  let colorBorderClasses = `border-offwhite `;

  if (color === "white") {
    colorClasses = `bg-offwhite text-offblack `;
    colorBorderClasses = `border-offblack `;
  }
  return (
    <>
      <SEO
          title={intl.formatMessage({ id: "festTeam" })}
          image={getSrc(data.team.edges[0].node.image)}
      />
      <Scroll callback={location} />
      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div className="grid md:grid-cols-4 pt-16 md:pt-16 xl:pt-20">
          <div
            className="absolute top-0 left-0 right-0 bottom-0"
            id="pinned-pane"
          />
          <motion.div
            variants={fade}
            className="ml-3 md:ml-0 pb-10 md:col-span-1 md:h-screen relative px-4 pt-5 md:py-24 md:mt-2 xl:mt-5 overflow-hidden md:mb-0"
            data-scroll-sticky
            data-scroll
            data-scroll-target="#pinned-pane"
          >
            <h1 className="mb-0 pb-0 mt-12 md:mt-0 xl:mt-0 relative about-title md:w-10/12 xl:w-8/12 xl:pr-0 textreveal">
              {intl.formatMessage({ id: "festTeam" })}
            </h1>

            <div className="scrollreveal mt-6 md:mt-20">
              <Link
                to="/festival/"
                className={
                  colorBorderClasses +
                  `textreveal text-base md:text-lg xl:text-xl uppercase text-center inline-block mx-auto font-medium border-b-2 group`
                }
              >
                <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                  <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                    <span className="block transform translate">
                      {intl.formatMessage({ id: "backFest" })}
                    </span>
                    <span className="hidden md:block">
                      {intl.formatMessage({ id: "backFest" })}
                    </span>
                  </span>
                </span>
              </Link>
            </div>
          </motion.div>

          <motion.div
            variants={fade}
            className="md:col-span-3 border-t md:border-l md:border-t-0 border-black bg-black texture-overlay texture-overlay--dark text-white relative"
          >
            <div className="content relative" id="content-pane">
              <div className="h-full">
                <div className="mb-12 md:mb-16 xl:mb-24 z-30">
                  <div className="hidden md:block h-screen relative z-40 pointer-events-none" data-scroll-sticky data-scroll data-scroll-target="#scroll-container">
                    <div className="w-full h-64 hidden md:block absolute bottom-0 left-0 right-0 z-40"/>
                  </div>

                  <div className="mt-0 md:-mt-100vh md:p-12 xl:p-16 relative z-30">
                    <div className="my-12 md:my-16 xl:my-24 px-4 md:px-0">
                      <div className="w-full mb-6 md:mb-12 xl:mb-16 mx-auto">
                        <div className="scrollreveal">
                          <h2 className="text-offwhite mb-12 text-center md:mb-16 xl:mb-24 lg:pt-2">
                            {intl.formatMessage({ id: "management" })}
                          </h2>
                        </div>
                      </div>
                      {data.team.edges.map(({ node }, i) => {
                        return (
                          <motion.div
                            varians={fade}
                            data-scroll
                            className="w-10/12 md:h-carouselX h-full md:w-1/2 mb-6 md:mb-12 xl:mb-16 mx-auto"
                            key={i}
                          >
                            <Link
                              className="block relative group about-image-teaser"
                              to={`/festival/management/${node.slug}/`}
                            >
                              <div className="overflow-hidden mb-5 scrollreveal">
                                <div className="overflow-hidden grayimage">
                                  <GatsbyImage
                                    image={node.image.gatsbyImageData}
                                    alt={node.image.title}
                                    className="w-full h-auto mb-0 pb-0 block transform transition ease-in-out duration-1000 group-hover:scale-110 group-focus:scale-110 scale-105 will-change"
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col items-center border-b border-offwhite pb-5 mb-5">
                                <h4 className="mb-0 pb-0">
                                  <span className="overflow-hidden block scrollreveal">
                                    <span className="text-offwhite block">{node.name}</span>
                                  </span>
                                </h4>
                                {node.title && (
                                  <span className="mx-auto overflow-hidden block scrollreveal">
                                    <span className="block">
                                      <span className="text-offwhite text-xs md:text-sm italic">
                                        {node.title}
                                      </span>
                                    </span>
                                  </span>
                                )}
                              </div>
                            </Link>
                            <div className="scrollreveal">
                              <Link
                                className="block relative group about-image-teaser"
                                to={`/festival/management/${node.slug}/`}
                              >
                                <div className="text-offwhite text-xs md:text-sm italic text-center mx-auto">
                                  {intl.formatMessage({ id: "learnMore" })}
                                </div>
                              </Link>
                            </div>
                          </motion.div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className=""
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="-mt-5 relative z-50">
            <Footer />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default injectIntl(FestivalManagement);

export const query = graphql`
  query ($language: String) {
    team: allContentfulManagement(filter: { node_locale: { eq: $language } }
      sort: { fields: priority, order: ASC }) {
      edges {
        node {
          name
          title
          slug
          about {
            raw
          }
          image {
            title
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
