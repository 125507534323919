import React from "react";
import { Link } from "gatsby-plugin-intl-v3";
import Logo from "../logo/logo";
import Marquee from "react-smooth-marquee";
import { injectIntl } from "gatsby-plugin-intl-v3";
import Language from "../Language";
import BookletLink from "../BookletLink";

const Header = ({ noLogo, intl }) => (
  <>
    <header className="border-b border-black border-t w-full bg-offwhite fixed top-16 left-0 right-0 z-60 texture-overlay">
      <div className="flex flex-wrap items-center">
        {/*FESTIVAL*/}
        <Link
          className="text-xs md:text-lg xl:text-xl uppercase font-medium block px-4 md:px-6 xl:px-8 py-4 md:py-5 xl:py-6 mr-auto border-r border-offblack relative overflow-hidden group"
          activeClassName="nav--active"
          partiallyActive
          to="/festival/"
        >
          <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
            <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
              <div className="block transform translate">
                {intl.formatMessage({ id: "festivalNav" })}
              </div>
              <div className="hidden md:block">
                {intl.formatMessage({ id: "festivalNav" })}
              </div>
            </span>
          </span>
          <span className="absolute bottom-0 left-0 right-0 w-full flex justify-start">
            <span className="block w-0 h-px bg-offblack transition-all duration-700 ease-in-out" />
          </span>
        </Link>
        {/*LOGO*/}
        <Link className="block px-2 xs:px-5 mx-auto" to="/">
          <span
            className={`centered-axis-x text-xs md:text-lg xl:text-xl uppercase font-medium block px-4 md:px-6 xl:px-8 py-4 md:py-5 xl:py-6 transition ease-in-out duration-1000 ${
              noLogo ? "opacity-100" : " opacity-0"
            }`}
          >
            <div className="overflow-hidden w-44 md:w-96 lg:w-128">
              <Marquee velocity={0.035}>
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;{intl.formatMessage({ id: "title" })}
                &nbsp;&nbsp; &bull;&nbsp;&nbsp;
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;{intl.formatMessage({ id: "title" })}
                &nbsp;&nbsp; &bull;&nbsp;&nbsp;
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;{intl.formatMessage({ id: "title" })}
                &nbsp;&nbsp; &bull;&nbsp;&nbsp;
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;{intl.formatMessage({ id: "title" })}
                &nbsp;&nbsp; &bull;&nbsp;&nbsp;
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;{intl.formatMessage({ id: "title" })}
                &nbsp;&nbsp; &bull;&nbsp;&nbsp;
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;{intl.formatMessage({ id: "title" })}
                &nbsp;&nbsp; &bull;&nbsp;&nbsp;
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;{intl.formatMessage({ id: "title" })}
                &nbsp;&nbsp; &bull;&nbsp;&nbsp;
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;{intl.formatMessage({ id: "title" })}
                &nbsp;&nbsp; &bull;&nbsp;&nbsp;
                {intl.formatMessage({ id: "title" })}&nbsp;&nbsp;
                &bull;&nbsp;&nbsp;{intl.formatMessage({ id: "title" })}
                &nbsp;&nbsp;
              </Marquee>
            </div>
          </span>

          <div
            className={`centered-axis-x-alt w-20 xs:w-24 md:w-32 xl:w-40 transition ease-in-out duration-1000 ${
              noLogo ? "opacity-0 " : "opacity-100"
            }`}
          >
            <Logo />

          </div>
        </Link>
        {/*ACADEMY*/}
        <Link
          className="text-xs md:text-lg xl:text-xl uppercase font-medium block px-4 md:px-6 xl:px-8 py-4 md:py-5 xl:py-6 ml-auto border-l border-offblack relative overflow-hidden group"
          activeClassName="nav--active"
          partiallyActive
          to="/academy/"
        >
          <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
            <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
              <span className="block transform translate">
                {intl.formatMessage({ id: "academyNav" })}
              </span>
              <span className="hidden md:block">
                {intl.formatMessage({ id: "academyNav" })}
              </span>
            </span>
          </span>
          <span className="absolute bottom-0 left-0 right-0 w-full flex justify-end">
            <span className="block w-0 h-px bg-offblack transition-all duration-700 ease-in-out" />
          </span>
        </Link>
      </div>
    </header>
    {/*LANGUAGE*/}
    <Language />
    <BookletLink/>
  </>
);

export default injectIntl(Header);
