import React from "react";

const Logo = () => (
  <svg
    className="w-full block will-change"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 716.89 201.78"
  >
    <g id="b">
      <g id="c">
        <g fill="#fff8e1">
          <path d="M716.87,85.87c-6.08,.15-12.17,.31-18.25,.43-1.6,.03-3.18,0-4.91,0,.24-.94,.24-1.39,.38-1.44,7.09-2.68,7.28-2.68,7.31-10.42,.1-19.44,.07-38.89,0-58.33-.03-7.61-.14-7.61-8.4-10.15,5.82-1.89,10.77-3.5,16-5.2-.22,4.84-.64,9.48-.62,14.11,.05,20.59,.12,41.16,.41,61.75,.09,6.51,1.44,7.45,8.09,7.5v1.72l-.02,.03Z" />
          <path d="M504.78,198.54c7.33-.72,6.73-5.8,6.75-10.53,.02-6.15,.09-12.29,0-18.44-.07-4.98,1.61-10.71-6.52-12.67l1.37-1.03c6.75-1.77,13.5-3.54,20.54-5.37v7.25c2.64-1.63,4.69-2.99,6.82-4.22,12.05-6.95,22.32-2.04,23.61,11.71,.72,7.8,.38,15.69,.38,23.54,0,4.67,.17,9.07,6.58,9.58-1.97,1.99-23.06,2.7-27.58,1.34,.1-.38,.15-1.08,.31-1.1,5.61-.94,5.65-5.19,5.55-9.43-.17-7.57-.27-15.14-.7-22.7-.21-3.59-2.4-6.04-5.92-7.11-5.49-1.65-9.24,1.03-9.2,6.68,.05,8,.22,16,.24,24.02,0,3.93,.14,7.64,5.36,8.07,.22,.02,.5,.27,.64,.5s.15,.52,.34,1.3h-27.78l-.74-1.39h-.02Z" />
          <path d="M655.07,153.44c7.76-.58,15.52-1.15,23.59-1.75v34.7c0,4.4,2.05,6.96,6.16,7.69-.82,3.07-14.29,7.88-20.86,7.33-.31-2.27-.62-4.6-.98-7.21-2.94,1.92-5.39,3.88-8.14,5.29-11.45,5.89-21.26,0-21.39-12.81-.07-7.14,0-14.3,0-21.45,0-4.89-2.35-8.52-7.06-10.89,3.97-2.42,7.81-1.17,11.25-1.67,3.66-.55,7.35-.86,10.99-1.27,0,9.13-.03,18.24,.02,27.34,0,2.56,.05,5.17,.46,7.69,.72,4.33,4.16,6.51,8.89,5.98,4.29-.48,5.46-1.94,5.48-7.04,.03-7.14,.03-14.3,0-21.45-.03-6.56-.12-6.64-6.58-8.29-.64-.15-1.24-.46-1.84-.7v-1.49Z" />
          <path d="M359.21,86.08h-23.64c1.15-.98,1.67-1.8,2.23-1.84,4.91-.38,5.89-3.28,5.7-7.66-.29-6.85,.09-13.74-.14-20.59-.15-5-1.25-9.87-6.51-12.12-5.49-2.35-10.7-1.12-15.28,2.49-1.05,.82-1.99,2.54-1.99,3.83,.02,9.15,.48,18.29,.41,27.44-.03,4.02,1.1,6.28,5.31,6.56,.67,.05,1.29,.69,1.77,1.8h-22.37c1.27-1.03,1.72-1.73,2.21-1.77,4.88-.19,5.7-3.31,5.61-7.33-.14-7.3-.1-14.59-.17-21.88-.05-5.27-1.84-9.72-7.09-11.64-5.34-1.96-10.23-.22-14.25,3.42-1.25,1.15-1.97,3.47-2.03,5.29-.21,8.43,.03,16.88-.15,25.33-.09,3.9,.98,6.35,5.2,6.71,.79,.07,1.55,.67,2.11,1.94h-22.01l-.34-.82c.94-.39,1.85-1.01,2.83-1.17,3.49-.52,4.79-2.63,4.82-5.94,.07-9.15,.05-18.32,.33-27.46,.14-4.62-2.87-6.11-7.73-7.57l14.59-5.6,.48,7.57c10.03-6.63,20-14.1,29.43,.38,3.21-1.97,6.32-4.17,9.67-5.91,11.59-5.99,21.05-.86,22.01,12.21,.58,8.12,.38,16.29,.46,24.45,.07,6.52,.02,6.51,6.44,8.14,.34,.09,.6,.5,2.06,1.73h.02Z" />
          <path d="M374.8,199.88h-35.3l-.19-1.2c1.25-.38,2.49-.96,3.78-1.08,3.93-.36,5.44-2.71,5.44-6.35v-57.09c0-3.38-1.72-5.27-5-5.92-1.37-.27-2.7-.76-4.04-1.13l.19-1.24h34.07l.43,.62c-1,.53-1.92,1.36-2.97,1.53-4.05,.65-5.87,2.85-5.86,7.01,.09,18.3,.07,36.61,.02,54.91,0,4.76,1.72,7.68,6.89,7.8,.89,.02,1.79,.62,2.66,.96l-.12,1.2h0Z" />
          <path d="M688.06,121.72c7.18-1.58,14.35-3.14,21.93-4.81-.24,4.09-.65,8.14-.67,12.19-.07,19.45-.03,38.91-.02,58.35,0,1.56,.22,3.14,.24,4.7,.03,2.9,1.05,4.95,4.22,5.51,.7,.12,1.27,1.01,2.8,2.3h-28.78c.22-.77,.29-1.44,.46-1.46,5.39-1,5.68-4.77,5.65-9.29-.17-19.16-.19-38.31,0-57.47,.05-4.77-.98-8.24-6.94-8.95l1.06-1.08h.03Z" />
          <path d="M453.7,192.53c-.67,1.56-.88,2.52-1.43,3.21-4.57,5.72-14.68,7.68-21.14,4.12-3.78-2.08-5.27-5.49-5.32-9.62-.1-9-.33-17.99-.03-26.99,.14-4.34-.58-7.02-6.73-5.98,1.3-1.82,1.97-3.62,2.75-3.67,4.58-.27,4.16-3.37,4.05-6.42-.07-1.85,0-3.71,0-5.63,5.03-2.34,9.94-4.64,15.35-7.16v17.33h12.21c-.36,2.2-.67,4.07-1,5.98h-10.94c0,10.75-.24,21.38,.14,31.99,.12,3.37,3.42,4.29,6.52,4.07,1.53-.1,3.04-.65,5.58-1.22h-.02Z" />
          <path d="M425.85,50.65c0,9.89-.21,19.28,.21,28.66,.07,1.49,2.66,3.26,4.46,4.17,1.84,.93,4.12,.98,6.22,1.41l-.17,1.08h-29.31l-.12-1.08c1-.26,1.97-.58,2.99-.76,6.01-.96,6.89-1.87,7.07-8.04,.14-5,.19-10.01,.17-15.01-.02-13.15-.05-26.32-.14-39.48-.05-7.95-.26-8.1-8.05-9.72-.6-.12-1.13-.57-1.56-1.79h50.96c-.64,5.25-1.3,10.85-1.99,16.43l-1.46,.03c-.57-2.52-1.15-5.05-1.67-7.57-.74-3.64-2.94-5.32-6.64-5.31-5.86,.05-11.73,.02-17.58-.1-2.54-.05-3.66,.98-3.66,3.5,.03,9.56,0,19.13,0,29.07,7.25,0,14.37,.29,21.45-.21,1.84-.14,3.73-2.56,5.15-4.29,.94-1.15,1.08-2.95,1.58-4.46l1.15,.27v21.6l-1.15,.38c-.52-1.01-1.24-1.99-1.49-3.06-.96-4.05-3.18-6.03-7.59-5.82-6.27,.27-12.55,.07-18.84,.07h.02Z" />
          <path d="M412.92,151.12c-.64,5.49-1.17,10.04-1.68,14.58l-1.3,.15c-.46-1.32-.98-2.63-1.37-3.98-1.63-5.65-4.89-8.89-9.8-8.43-2.3,.22-5.19,1.75-6.47,3.61-1.84,2.63-.45,5.67,2.18,7.42,3.67,2.44,7.61,4.52,11.42,6.75,4.55,2.64,8.72,5.67,9.39,11.45,.72,6.23-1.41,11.35-6.68,14.78-8.77,5.73-18.18,4.88-27.64,2.03-.64-.19-1.48-1.1-1.49-1.7-.14-4.55-.12-9.1-.07-13.67,0-.21,.67-.41,1.39-.84,.98,2.58,1.75,5.1,2.85,7.47,2.61,5.6,7.38,8.64,13,7.59,2.15-.39,5.08-2.27,5.63-4.07,.55-1.84-.76-5.22-2.37-6.54-3.73-3.06-8.19-5.17-12.23-7.85-4.46-2.97-7.64-6.78-7.76-12.57-.19-8.4,4.05-14.82,12.35-16,6.64-.96,13.55-.19,20.69-.19l-.02,.02Z" />
          <path d="M543.89,42.29c2.03-1.27,3.13-2.47,4.33-2.59,3.23-.31,4.26-2.04,3.66-4.88-1.29-6.04,2.97-8.84,7.13-12.59v16.28h13.43c.65,3.83-.46,5.53-4.38,5.17-2.82-.26-5.67-.05-9.27-.05v5.34c0,6.99-.03,13.99,0,20.98,.07,11.37,3.18,13.75,14.11,10.83-.69,3.62-4.57,6.25-9.8,6.61-5,.36-9.08-1.92-10.29-6.47-.88-3.25-1.08-6.75-1.12-10.15-.09-7.28-.12-14.58,.33-21.82,.26-4.19-.94-6.18-5.25-5.92-.52,.03-1.03-.26-2.85-.76l-.02,.02Z" />
          <path d="M511.11,71.84c.93,2.39,1.22,4.65,2.47,5.99,2.46,2.58,5.17,5.32,8.33,6.7,3.93,1.68,8.55-.69,10.25-4.09,1.7-3.37,.94-7.68-2.47-10.42-2.99-2.4-6.44-4.19-9.67-6.27-3.78-2.44-7.28-5.12-8.4-9.84-2.08-8.86,3.97-16.24,14.08-16.76,4.07-.21,8.19,.53,12.67,.86-.58,4.41-1.1,8.28-1.61,12.14h-1.32c-.36-1.25-.84-2.49-1.03-3.76-.65-4.41-3.42-6.39-7.59-6.75-3.76-.33-6.87,.86-8.43,4.5-1.63,3.79-1.06,7.54,2.23,10.08,3.35,2.61,7.19,4.6,10.82,6.85,4.34,2.7,8.33,5.65,8.57,11.45,.29,7.42-4.91,13.32-13.32,14.75-2.09,.36-4.28,.34-6.4,.21-8.6-.58-12.57-5.87-10.75-14.27,.09-.41,.77-.7,1.58-1.39v.02Z" />
          <path d="M630.66,38.93h19.52c-.17,.69-.21,1.17-.31,1.17-7.06,.79-8.72,6.51-10.78,11.83-4.14,10.65-8.17,21.33-12.38,31.95-.43,1.12-1.63,1.92-2.47,2.87-.88-.88-2.18-1.6-2.58-2.66-3.86-10.29-7.5-20.67-11.38-30.96-2.2-5.84-3.07-12.95-12.24-12.95l.72-1.15h21.03c-.09,.52-.03,.94-.19,1.03-5.34,3.13-5.37,3.14-3.11,9.31,3.13,8.43,6.32,16.84,9.94,26.48,2.37-6.34,4.4-11.57,6.3-16.86,1.41-3.9,2.7-7.83,4.02-11.76,1.15-3.42,.72-5.92-3.59-6.28-.91-.09-1.79-.62-2.68-.96l.17-1.05h0Z" />
          <path d="M361.4,41.14l14.49-3.76v32.31c0,1.43-.02,2.87,0,4.29,.15,9.48-1.13,7.74,7.18,11.64h-21.91c.09-.36,.1-.62,.15-.62,7.07-1.15,7.45-6.2,7.28-11.95-.26-8.57,0-17.14-.27-25.7-.03-1.34-1.46-2.8-2.56-3.88-.89-.88-2.27-1.25-4.36-2.34Z" />
          <path d="M577.48,41.17c5.24-1.36,9.44-2.44,13.91-3.61v24.88c0,4.86,.38,9.72,.05,14.54-.29,4.46,1.36,6.77,5.77,7.18,.62,.05,1.2,.41,1.68,1.58h-22.34c.58-.67,.91-1.36,1.25-1.36,5.82-.09,6.44-4.02,6.42-8.5-.02-8.98-.1-17.96,.05-26.94,.05-3.25-1.15-5.12-4.17-6.03-.69-.21-1.25-.82-2.63-1.77v.02Z" />
          <path d="M356.7,120.21c-4.29,.03-6.61-2.28-6.56-6.59,.05-4.07,2.85-6.18,8.09-6.13,3.86,.05,5.77,1.97,5.82,5.86,.05,4.69-2.21,6.8-7.35,6.85v.02Z" />
          <path d="M371.48,24.18c-2.95,.21-4.05-1.43-4.16-4.12-.12-3.28,2.32-4.46,4.67-4.16,1.48,.19,3.59,2.34,3.83,3.86,.41,2.58-1.22,4.57-4.33,4.41h-.02Z" />
          <path d="M592.3,20.98c-1.77,1.06-3.43,2.68-5.34,3.07-2.88,.6-3.98-1.61-3.98-4.17,0-3.19,2.47-4.26,4.88-4,1.58,.17,2.99,1.97,4.46,3.04l-.03,2.06h.02Z" />
          <path d="M98.22,58.83c8-7.3,13.62-16.36,24.3-19.73l-.79-1.15-20.4,1.18-.15,1.1,6.95,2.56c-4.74,8.91-13.89,17.15-18.01,16.93V.91l-15.28,3.25c1.24,1.24,2.42,1.67,3.62,1.75,2.78,.21,4.1,1.6,4.1,4.4,.02,22.44,.12,44.9,.03,67.34-.02,5.25-1.94,6.52-7.18,6.56-6.52,.03-9.29-4.05-11.09-9.43-6.71-20.04-13.62-40.01-20.02-60.13-1.41-4.4-3.37-5.27-6.52-4.29-8.45,22.12-16.71,43.72-24.95,65.32-2.27,5.92-6.39,8.93-12.83,8.48v2.58l26.27-.86,.1-1.05c-1.27-.38-2.54-.81-3.83-1.13-5.48-1.37-6.18-2.3-4.45-7.78,2.06-6.54,4.36-13,6.87-19.37,.46-1.15,2.39-2.39,3.67-2.46,5.72-.27,11.45-.27,17.15,0,1.25,.05,3.14,1.39,3.57,2.56,2.7,7.38,4.98,14.9,7.56,22.32,.96,2.8-.09,3.98-2.7,4.53-1.61,.34-3.21,.88-4.81,1.32l.24,1.18h48.09l.22-1.1c-1.36-.34-2.88-.41-4.04-1.1-1.43-.86-3.55-2.16-3.64-3.38-.38-5.79-.17-11.62-.17-18.66,2.15,1.53,3.91,2.35,5.05,3.69,4.79,5.68,9.24,11.66,14.08,17.29,3.62,4.21,12.86,5.43,18.18,2.64-14.72-3.52-19.88-16.96-29.22-26.56ZM27.42,49.55c3.33-9.8,6.52-19.2,9.74-28.57l1.06-.12c3,9.5,6.03,18.97,9.1,28.69H27.42Z" />
          <path d="M217.43,73.64c.34-24.55,.98-49.09,1.49-73.64h-.86l-17.22,4.38c2.54,.64,4.29,.72,5.63,1.51,1.43,.82,3.49,2.28,3.52,3.52,.29,9.38,.15,18.77,.15,28.37-1.36-.21-2.47-.38-3.59-.53-17.65-2.42-33.09,11.49-32.4,29.19,.69,18.01,14.59,25.96,30.25,17.29,1.79-.98,3.55-1.99,5.55-3.11l.65,8.57,14.63-5.94c-7.57-2.32-7.92-2.34-7.81-9.6Zm-7.04-.69c.12,1.88-.28,3.33-1.11,4.5,0,.01-.02,.02-.03,.03-.2,.28-.42,.54-.66,.79-.07,.07-.15,.12-.22,.19-.74,.69-1.64,1.27-2.71,1.78-2.18,1.03-4.46,1.54-6.71,1.58-6.74,.13-13.22-3.95-15.83-10.9-2.27-6.04-2.52-12.31-.6-18.51,2.27-7.34,9.33-11.59,16.34-11.21,3.19,.17,6.37,1.29,9.08,3.51,1.25,1.03,2.22,3.06,2.34,4.69,.33,4.26,.1,8.57,.1,12.84h-.03c0,3.57-.19,7.16,.05,10.71Z" />
          <path d="M613.95,153.61c-7.01-5.43-14.25-3.47-21.36,.09-1.32,.67-2.68,1.27-3.95,1.87v-38.38c-7.69,1.51-15.63,3.04-23.8,4.64,.53,.79,.69,1.32,1,1.44,6.68,2.49,6.75,2.49,6.78,9.39,.09,19.45,.1,38.89,.15,58.35v8.04l.02-.03c3.73,.6,7.23,.93,10.61,1.79,14.61,3.71,37.67-5.65,38.75-25.34,.46-8.6-1.06-16.33-8.21-21.84Zm-9.68,38.84c-.31,.8-.71,1.51-1.19,2.11-1.42,1.81-3.57,2.68-6.33,2.11-4.09-.82-7.37-2.85-7.92-7.25-.64-5.22-.72-10.49-1.03-15.75h.02v-.03h.27c0-3.84,.14-7.7-.05-11.52-.04-.82,.03-1.49,.2-2.04,.49-1.65,1.9-2.22,4.13-2.29,4.95-.14,8.77,1.87,10.94,6.11,4.72,9.27,4.69,18.94,.96,28.54Z" />
          <path d="M495.54,186.56c-.09-7.13,.39-14.32-.27-21.39-.91-9.68-8.95-16.47-17.58-14.46-5.56,1.29-10.85,4.26-15.88,7.13-3.3,1.87-3.61,6.68-1.08,8.89h.02c.69-1.1,1.39-2.13,2.04-3.21,2.71-4.52,6.49-6.03,11.57-4.64,4.26,1.17,7.01,4.91,7.33,9.53,.21,2.73-.76,4.29-3.4,5.05-5.07,1.46-10.04,3.28-15.16,4.55-3.95,.98-5.7,3.5-6.18,7.19-.72,5.61,.81,10.59,5.24,14.22,4.45,3.62,9.13,2.25,13.6-.27,1.84-1.03,3.61-2.18,5.41-3.3,2.35,5.03,5.44,6.54,11.18,5.61,6.1-1,9.51-3.67,10.08-7.9-5.77-.07-6.83-1.12-6.9-7.01Zm-14.08,3.97c-.02,2.18-1.27,2.85-3,2.83-.25,0-.51-.02-.77-.05-3.52-.36-6.46-3.66-7.07-8-.6-4.24,.82-6.59,4.95-7.98,1.83-.62,3.73-1.1,5.91-1.73h0c0,5.34,.05,10.13,0,14.93Z" />
          <path d="M161.3,73.44c.02-.84,.12-1.7,.1-2.56-.22-7.97,.05-16-.88-23.88-.72-6.1-6.3-10.83-11.66-9.68-5.37,1.17-10.65,3.86-15.32,6.89-3.3,2.15-2.8,6.01,.05,7.78,.69-1.56,1.3-3.14,2.06-4.67,2.92-5.89,9.31-7.23,14.58-3.18,4.1,3.16,4.53,7.66,4.74,12.29,.1,2.42-.64,3.98-3.18,4.79-5.56,1.77-10.99,4.02-16.59,5.65-3.64,1.06-5.08,3.31-5.29,6.77-.29,5.03,1.37,9.29,5.34,12.45,3.66,2.92,7.28,1.37,10.82-.38,2.66-1.32,5.27-2.78,8.12-4.29l.03-.03c1.03,4.48,3.37,6.99,8.19,6.16,4.26-.72,6.87-2.82,7.18-6.04-7.43,.34-8.4-.58-8.31-8.05Zm-6.8,2.13c-.1,3.47-2.95,5.51-7.98,5.96-4.22,.38-8.17-2.2-9.07-6.16-.2-.88-.31-1.73-.3-2.52,.01-2.37,1.09-4.26,4.15-5.26,4.29-1.41,8.48-3.13,13.24-4.91v.08c0,4.91,.1,8.85-.03,12.81Z" />
          <path d="M692.32,81.7c-8.95-.31-9.13-.53-9.08-9.19,.05-7.14,.33-14.29,.05-21.41-.29-7.68-4.19-12.41-10.56-13.75-4.76-1-15.8,3.64-18.11,7.85-1.24,2.23-2.82,4.81,.67,7.18,.91-2.01,1.63-3.81,2.52-5.55,2.37-4.58,4.76-5.72,9.75-4.77,5.98,1.12,9.22,6.2,9.38,14.41,.05,2.47-.72,3.95-3.23,4.76-5.43,1.75-10.7,4-16.17,5.51-4.05,1.12-5.56,3.64-5.67,7.38-.12,4.84,1.55,9,5.44,12,3.69,2.85,7.31,1.29,10.83-.46,2.66-1.32,5.25-2.76,8.09-4.28,.86,3.93,2.56,6.75,6.92,6.28,3.91-.41,7.31-1.72,9.19-5.92l-.02-.03Zm-19.59-1.25c-3.83,1.39-7.5,1.85-10.85-1.08-1.98-1.74-2.78-3.88-2.7-6.21,.02-.78,.15-1.57,.35-2.38,.22-.93,1.27-1.94,2.2-2.34,4.69-1.99,9.46-3.79,14.8-5.91,0,4.5-.14,8.07,.05,11.61,.02,.39,.03,.76,0,1.12-.11,2.5-1.18,4.22-3.86,5.18Z" />
          <g>
            <path d="M270.49,57.16h0v0Z" />
            <path d="M243,59.2c9.06-.5,18.13-1.34,27.48-2.04-1.16-3.51-1.92-5.74-2.66-7.97-2.47-7.49-7.57-11.93-13.91-12.11-8.95-.24-18.46,5.39-21.12,13.99-1.72,5.53-2.23,11.78-1.72,17.58,.76,8.59,4.89,15.61,13.93,18.17,9.25,2.61,16.74-1.2,23.4-7.25,.7-.64,.94-1.8,1.39-2.71-1.18-.14-1.77,.17-2.3,.55-12.28,8.57-26.65,2.97-29.72-11.57-1.34-6.35-1.2-6.3,5.22-6.64Zm6.04-18.68c6.04-.17,11.3,5.85,11.93,14.1,.02,.27,.05,.53,.06,.8h-23.04c-.03-.5-.03-.99-.02-1.47,.2-7.29,4.96-13.29,11.08-13.43Z" />
          </g>
          <path d="M470.75,66.45c-1.61-6.51-1.17-6.89,5.48-7.25,8.93-.48,17.86-1.34,27.1-2.04-1.17-3.54-1.91-5.79-2.66-8.04-2.49-7.52-7.56-11.9-13.93-12.05-9.15-.21-18.13,5.79-21.46,14.41-2.39,6.18-2.51,12.59-1.13,18.97,1.77,8.24,5.94,14.66,14.7,16.64,8.95,2.03,16.21-1.68,22.54-7.62,.65-.6,.81-1.75,1.18-2.66-1.25-.03-1.8,.36-2.37,.76-11.92,8.26-25.96,2.95-29.45-11.11Zm10.41-26c6.35-.79,11.88,5.24,13.02,14.92v.02h0v.03c-7.76,.34-15.45,.67-23.09,1.01-.06-.51-.1-1.01-.11-1.51-.25-7.36,4.32-13.73,10.19-14.47Z"/>
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
