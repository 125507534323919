import React from "react";
import {injectIntl} from "gatsby-plugin-intl-v3";

const BookletLink = ({intl}) => {
  return (
    <div className="text-center h-8 border-black border-t w-full bg-offwhite fixed top-8 left-0 right-0 z-60 texture-overlay flex w-full flex-row items-center justify-center">
          <a
              className="text-center text-xs md:text-sm cursor-pointer inline-block relative"
              href="/festival/akademi-festival-istanbul-2022-program-kitapcigi.pdf"
              target="_blank"
          >
            <span className="text-center text-black hover:text-pink-900 focus:text-pink-900 transition duration-500 ease-in-out">
              {intl.formatMessage({ id: "booklet" })}
            </span>
          </a>
    </div>
  );
};

export default injectIntl(BookletLink);
