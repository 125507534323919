import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { IntlProvider, IntlContextProvider } from "gatsby-plugin-intl-v3";
import { Link } from "gatsby-plugin-intl-v3";
import PropTypes from "prop-types";
import Header from "./header/header";
import Logo from "./logo/logo";
import "../styles/main.css";

const Layout = (props) => {
  const [state, setState] = useState({
    showIntro: false,
    timing: 5.5,
  });

  useEffect(() => {
    setTimeout(function () {
      setState({
        showIntro: false,
        timing: 0,
      });
    }, 5800);
  });
  const intl = props.pageContext.intl;

  const path = intl.originalPath;
  const children = props.children;

  return (
    <IntlProvider
      locale={intl.language}
      defaultLocale={intl.defaultLanguage}
      messages={intl.messages}
    >
      <IntlContextProvider value={intl}>
        {state.showIntro && (
          <motion.section initial="initial" animate="enter" exit="exit">
            <motion.div
              animate={{ translateY: "-100%" }}
              transition={{
                duration: 0.1,
                delay: 5.5,
                ease: [0.76, 0, 0.24, 1],
              }}
              className="fixed w-full h-screen z-70"
            >
              <motion.div
                animate={{ opacity: 0 }}
                transition={{
                  duration: 1.25,
                  delay: 4.25,
                  ease: [0.76, 0, 0.24, 1],
                }}
                className="h-screen w-full z-40 bg-offwhite flex flex-wrap"
              >
                <motion.div className="h-screen w-full z-40 bg-offwhite text-offblack px-5 md:px-8 font-sans pt-10 md:pt-12 xl:pt-16">
                  <div className="border-b border-offwhite w-full bg-offwhite fixed top-8 left-0 right-0 z-40 texture-overlay">
                    <motion.div
                      initial={{ translateY: 0 }}
                      animate={{ translateY: "-100%" }}
                      transition={{
                        duration: 1,
                        delay: 3.75,
                        ease: [0.76, 0, 0.24, 1],
                      }}
                      className="w-full h-10px bg-offwhite absolute top-0 left-0 right-0 z-10"
                    />
                    <div className="w-full h-10px bg-black relative z-0 absolute top-0 left-0 right-0 z-0" />

                    <motion.div
                      initial={{ translateX: "-100%" }}
                      animate={{ translateX: 0 }}
                      transition={{
                        duration: 2,
                        delay: 2,
                        ease: [0.76, 0, 0.24, 1],
                      }}
                      className="w-full h-px bg-black absolute bottom-0 left-0 right-0 z-10"
                    />

                    <div className="flex flex-wrap items-center">
                      <Link
                        className="text-xs md:text-lg xl:text-xl uppercase font-medium block px-4 md:px-6 xl:px-8 py-4 md:py-5 xl:py-6 mr-auto border-r border-offwhite relative overflow-hidden"
                        to="/festival/"
                      >
                        <motion.span
                          initial={{ translateY: "100%" }}
                          animate={{ translateY: 0 }}
                          transition={{
                            duration: 1.5,
                            delay: 2.65,
                            ease: [0.76, 0, 0.24, 1],
                          }}
                          className="block absolute top-0 right-0 bottom-0 w-px h-full bg-black"
                        />
                        <span className="overflow-hidden block">
                          <motion.span
                            initial={{ translateY: "100%" }}
                            animate={{ translateY: 0 }}
                            transition={{
                              duration: 1,
                              delay: 2.5,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block"
                          >
                            <span className="">
                              {intl.messages["festivalNav"]}
                            </span>
                          </motion.span>
                        </span>
                        <span className="absolute bottom-0 left-0 right-0 w-full flex justify-start">
                          <span className="block w-0 h-px bg-offblack transition-all duration-700 ease-in-out" />
                        </span>
                      </Link>
                      <Link className="block px-2 xs:px-5 mx-auto" to="/">
                        <div className={`w-24 md:w-32 xl:w-40 opacity-0`}>
                          <Logo />
                        </div>
                      </Link>
                      <Link
                        className="text-xs md:text-lg xl:text-xl uppercase font-medium px-4 md:px-6 xl:px-8 py-4 md:py-5 xl:py-6 ml-auto border-l border-offwhite relative block overflow-hidden"
                        to="/academy/"
                      >
                        <motion.span
                          initial={{ translateY: "100%" }}
                          animate={{ translateY: 0 }}
                          transition={{
                            duration: 1.5,
                            delay: 2.95,
                            ease: [0.76, 0, 0.24, 1],
                          }}
                          className="block absolute top-0 left-0 bottom-0 w-px h-full bg-black"
                        />

                        <span className="overflow-hidden block">
                          <motion.span
                            initial={{ translateY: "100%" }}
                            animate={{ translateY: 0 }}
                            transition={{
                              duration: 1,
                              delay: 3.25,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block"
                          >
                            {intl.messages["academyNav"]}
                          </motion.span>
                        </span>
                        <span className="absolute bottom-0 left-0 right-0 w-full flex justify-end">
                          <span className="block w-0 h-px bg-offblack transition-all duration-700 ease-in-out" />
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div className="flex flex-wrap w-full pt-16 md:pt-20 xl:pt-24 relative">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{
                        duration: 2,
                        delay: 0,
                        ease: [0.76, 0, 0.24, 1],
                      }}
                      className="w-full md:w-9/12 opacity-0 relative"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1127 240"
                        className=""
                      >
                        <defs>
                          <clipPath id="clip-logo-stroke">
                            <rect width="1127" height="240" />
                          </clipPath>
                        </defs>

                        {/* Clip path for stroke */}
                        <g id="logo-stroke" clipPath="url(#clip-logo-stroke)">
                          {/* Negative offset translate here... */}
                          <g id="Group_107" data-name="Group 107">
                            {/* I first */}
                            <motion.path
                              initial={{ translateY: 500.666 }}
                              animate={{ translateY: 0 }}
                              transition={{
                                duration: 2,
                                delay: 0.5,
                                ease: [0.76, 0, 0.24, 1],
                              }}
                              d="M0,216.61V3.07H58V216.61Z"
                              fill="#333"
                            />

                            {/* C */}
                            <motion.path
                              initial={{ translateY: 500.666 }}
                              animate={{ translateY: 0 }}
                              transition={{
                                duration: 2,
                                delay: 0.75,
                                ease: [0.76, 0, 0.24, 1],
                              }}
                              d="M218.65,22.2a77.07,77.07,0,0,0-45.5,14.17q-20.09,14.16-31.84,41.55t-11.76,59.67q0,27.32,14.25,43.6T183,197.47q20.31,0,47-8.32v21.76a147.22,147.22,0,0,1-23.95,6.57,157.36,157.36,0,0,1-27.46,2.05q-35.35,0-55.5-21.84T103,138.17q0-38.11,15.41-70.91t41.41-50Q185.79,0,218.65,0q28.77,0,50.83,11.68L259.4,32.28Q239.24,22.2,218.65,22.2Z"
                              fill="#333"
                            />
                            {/* F */}
                            <motion.path
                              initial={{ translateY: 500.666 }}
                              animate={{ translateY: 0 }}
                              transition={{
                                duration: 2,
                                delay: 1,
                                ease: [0.76, 0, 0.24, 1],
                              }}
                              d="M293.73,216.61H268.6L313.74,3.07H421.09l-4.38,22.34h-83l-16.07,77.85H394.8l-4.67,22.35H312.86Z"
                              fill="#333"
                            />

                            {/* E FILLED*/}
                            <motion.path
                              initial={{ translateY: 500.666 }}
                              animate={{ translateY: 0 }}
                              transition={{
                                duration: 2,
                                delay: 1.25,
                                ease: [0.76, 0, 0.24, 1],
                              }}
                              d="M517.2,216.61H409.84L455,3.07H562.33l-4.67,22.34H475L460.67,93.92h77l-4.24,22.2H456.14l-16.65,78.29H522Z"
                              fill="#333"
                            />

                            {/* M */}
                            <motion.path
                              initial={{ translateY: 500.666 }}
                              animate={{ translateY: 0 }}
                              transition={{
                                duration: 2,
                                delay: 1.5,
                                ease: [0.76, 0, 0.24, 1],
                              }}
                              d="M636.09,216.61V63.39h-1.17q-9.78,60.9-12.56,74.78l-16.5,78.44H555L600.16,3.07h75.66V154.68H677L746.8,3.07h76.83L777.91,216.61H726.65l17.09-80.19q7.29-33.3,15.77-63.68l2.19-9.35h-1.17l-70.4,153.22Z"
                              fill="#333"
                            />

                            {/* A */}
                            <motion.path
                              initial={{ translateY: 500.666 }}
                              animate={{ translateY: 0 }}
                              transition={{
                                duration: 2,
                                delay: 1.75,
                                ease: [0.76, 0, 0.24, 1],
                              }}
                              d="M947.49,176.59H882.05l-18.25,40H804.5L913.16,3.07h73.47l18,213.54H949ZM902.94,131h42.8l-2.49-61.93L943,56.53q0-11,.59-19.14a160.76,160.76,0,0,1-8.91,24.25Z"
                              fill="#333"
                            />
                          </g>
                        </g>
                      </svg>
                    </motion.div>
                    <div className="w-full md:w-3/12 hidden md:block self-center">
                      <motion.div
                        initial={{ x: "105%" }}
                        animate={{ x: "0" }}
                        transition={{
                          duration: 3,
                          delay: 1.25,
                          ease: [0.76, 0, 0.24, 1],
                        }}
                        className="w-full h-px bg-offblack ml-8"
                      />
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.section>
        )}
        <div className="w-full h-screen absolute top-0 left-0 right-0 bottom-0 texture-overlay z-0" />{" "}
        <Header noLogo={path === "/" ? true : false} />
        <main id="scroll-container" data-scroll-container>
          <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
        </main>
      </IntlContextProvider>
    </IntlProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default Layout;
