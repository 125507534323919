import React, { useState } from 'react';
import Thanks from '../../components/thanksContact'
import axios from 'axios'
import Footer from "../../components/footer/footer";
import { motion } from "framer-motion";
import Scroll from "../../components/locomotiveScroll";
import { fade } from "../../helpers/transitionHelper";
import { injectIntl, Link } from "gatsby-plugin-intl-v3";
import SEO from "../../components/seo";

const ApplyFormPage = ({ intl, location }) => {
  const color = "white";
  let colorBorderClasses = `border-offwhite `;

  if (color === "white") {
    colorBorderClasses = `border-offblack `;
  }

    const [formState, updateForm] = useState({
        name: '',
        lastName: '',
        email: '',
        instrument: '',
        phone: '',
        birth:'',
        country:'',
        postCode:'',
        cv:'',
        reperForm:'',
        videoTwo:'',
        videoOne:'',
        education:'',
        address:'',
        citizen:''
    });
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const updateInput = event => {
        updateForm({ ...formState, [event.target.name]: event.target.value });
    };

    const submitContact = (e) => {
        e.preventDefault();
        const { name,lastName, email, phone, instrument, birth, country, postCode, cv, reperForm, videoTwo, videoOne, education, address, citizen } = formState;

        axios
            .post("https://a2d8nufoq7.execute-api.us-east-1.amazonaws.com/sender/application",
                {body: { name,lastName, email, phone, instrument, birth, country, postCode, cv, reperForm, videoTwo, videoOne, education, address, citizen }})
            .then((res) => {
                console.log(res)
            }).then(() => {
            updateForm({
                name: '',
                lastName: '',
                email: '',
                instrument: '',
                phone: '',
                birth:'',
                country:'',
                postCode:'',
                cv:intl.formatMessage({ id: "thxApplication" }),
                reperForm:'',
                videoTwo:'',
                videoOne:'',
                education:'',
                address:'',
                citizen:''
            });
            setHasSubmitted(true);
        }).catch(err => {
            console.log('Error in post request by axios',err);
        })
    };


    return (
    <>
      <Scroll callback={location} />
        <SEO
            title={intl.formatMessage({ id: "applyButton" })}
        />
      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div className="grid pt-16 md:grid-cols-4 md:pt-16 xl:pt-20">
          <div
            className="absolute top-0 right-0 bottom-0 left-0"
            id="pinned-pane"
          />
          <motion.div
            variants={fade}
            className="overflow-hidden relative px-4 pt-5 md:col-span-1 md:h-screen md:py-24 pb-10 md:mt-2 xl:mt-5 md:mb-0"
            data-scroll-sticky
            data-scroll
            data-scroll-target="#pinned-pane"
          >
            <h1 className="ml-3 md:ml-0 relative mb-0 pb-0 mt-12 md:mt-0 xl:mt-0 about-title md:w-10/12 xl:w-8/12 xl:pr-0 textreveal">
              {intl.formatMessage({ id: "applyButton" })}
            </h1>

            <div className="ml-3 md:ml-0 mt-6 md:mt-20 scrollreveal">
              <Link
                to="/academy/"
                className={
                  colorBorderClasses +
                  `textreveal text-base md:text-lg xl:text-xl uppercase text-center inline-block mx-auto font-medium border-b-2 group`
                }
              >
                <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                  <span className="block transition duration-300 ease-in-out transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 md:-mt-px md:leading-tight">
                    <span className="block transform translate">
                      {intl.formatMessage({ id: "backAcademy" })}
                    </span>
                    <span className="hidden md:block">
                      {intl.formatMessage({ id: "backAcademy" })}
                    </span>
                  </span>
                </span>
              </Link>
            </div>
          </motion.div>

          <motion.div
            variants={fade}
            className="relative border-t border-black md:col-span-3 md:border-l md:border-t-0 hidden"
          >
            <div className="relative content" id="content-pane">
              <div className="h-full">
                <div className="z-30 mb-12 md:mb-16 xl:mb-24">
                    <div className="hidden md:block h-screen relative z-40 pointer-events-none" data-scroll-sticky data-scroll data-scroll-target="#scroll-container">
                        <div className="w-full h-64 hidden md:block absolute bottom-0 left-0 right-0 z-40"/>
                    </div>
                    <div className="mt-0 md:-mt-100vh md:p-12 xl:p-16 relative z-30">
                    <div className="px-4 my-12 md:my-16 xl:my-24 md:px-0">
                      <div className="mx-auto mb-6 w-full md:mb-12 xl:mb-16">
                        <div className="scrollreveal">
                          <h2 className="w-full md:w-9/12 ml-3 md:ml-0 mb-12 text-left md:mb-16 xl:mb-24 lg:pt-2">
                            {intl.formatMessage({ id: "appFormTitle" })}
                          </h2>
                        </div>
                      </div>

                        {!hasSubmitted ? (
                      <form
                          className="px-3 block w-full md:w-9/12"
                          id="applicationForm"
                          name="Application"
                          method='POST'
                          data-cy='applicationForm'
                          onSubmit={submitContact}
                      >
                          <input type='hidden' name='form-name' value="Application" />
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                              type="text"
                              value={formState.name}
                              onChange={updateInput}
                              name='name'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({id: "firstName"})}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                              type="text"
                              value={formState.lastName}
                              name="lastName"
                              onChange={updateInput}
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "lastName" })}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                              value={formState.instrument}
                              onChange={updateInput}
                              type='text'
                              name='instrument'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({id: "instrument"})}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                              value={formState.birth}
                              onChange={updateInput}
                              type='text'
                              name='birth'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "birth" })}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                              value={formState.citizen}
                              onChange={updateInput}
                              type='text'
                              name='citizen'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "citizen" })}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.25,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                              value={formState.email}
                              onChange={updateInput}
                              type='email'
                              name='email'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "email" })}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.3,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                              value={formState.phone}
                              onChange={updateInput}
                              type='tel'
                              name='phone'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "phone" })}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                              value={formState.address}
                              onChange={updateInput}
                              type='text'
                              name='address'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "address" })}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                            type="number"
                            value={formState.postCode}
                            onChange={updateInput}
                            name='postCode'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "postCode" })}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                            type="text"
                            value={formState.country}
                            onChange={updateInput}
                            name='country'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "country" })}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-6 md:mb-12">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.35,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <textarea
                            rows="3"
                            value={formState.education}
                            onChange={updateInput}
                            name='education'
                            maxlength="150"
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({id: "education"})}
                          />
                        </span>

                        <span className="block overflow-hidden relative mb-6 md:mb-12">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.35,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <textarea
                            rows="12"
                            value={formState.cv}
                            onChange={updateInput}
                            name='cv'
                            maxlength="500"
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "cv" })}
                          />
                        </span>

                          <span className="block overflow-hidden relative mb-6 md:mb-12">
                          <motion.span
                              initial={{translateX: "-100%"}}
                              animate={{translateX: 0}}
                              transition={{
                                  duration: 1,
                                  delay: 0.35,
                                  ease: [0.76, 0, 0.24, 1],
                              }}
                              className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <textarea
                              rows="6"
                              value={formState.reperForm}
                              onChange={updateInput}
                              name='reperForm'
                              maxLength="300"
                              className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                              placeholder={intl.formatMessage({id: "reperForm"})}
                          />
                        </span>

                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                            type="url"
                            value={formState.videoOne}
                            onChange={updateInput}
                            name='videoOne'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "videoOne" })}
                            required
                          />
                        </span>
                        <span className="block overflow-hidden relative mb-3 md:mb-5">
                          <motion.span
                            initial={{ translateX: "-100%" }}
                            animate={{ translateX: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.2,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block absolute bottom-0 left-0 w-full h-px bg-black"
                          />
                          <input
                            type="url"
                            value={formState.videoTwo}
                            onChange={updateInput}
                            name='videoTwo'
                            className="block py-3 w-full font-sans text-sm md:text-lg border-black bg-offwhitelight focus:bg-offwhite md:text-xl 3xl:text-2xl focus:outline-none hover:outline-none"
                            placeholder={intl.formatMessage({ id: "videoTwo" })}
                          />
                        </span>

                        <span className="block overflow-hidden relative">
                          <motion.span
                            initial={{ translateY: "100%" }}
                            animate={{ translateY: 0 }}
                            transition={{
                              duration: 1,
                              delay: 0.65,
                              ease: [0.76, 0, 0.24, 1],
                            }}
                            className="block"
                          >
                            <button
                              type="submit"
                              className="mx-auto my-6 md:my-20 block text-base font-medium uppercase border-b border-black focus:outline-none hover:outline-none text-lg md:text-xl group"
                            >
                              <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                                <span className="block transition duration-300 ease-in-out transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 md:-mt-px md:leading-tight">
                                  <span className="block transform translate">
                                    {intl.formatMessage({ id: "send" })}
                                  </span>
                                  <span className="hidden md:block">
                                    {" "}
                                    {intl.formatMessage({ id: "send" })}
                                  </span>
                                </span>
                              </span>
                            </button>
                          </motion.span>
                        </span>

                      </form>
                        ) : (
                            <Thanks message={intl.formatMessage({ id: "thxApplication" })} />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className=""
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="relative z-50 -mt-5">
            <Footer />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default injectIntl(ApplyFormPage);
