import React from "react";
import Footer from "../../components/footer/footer";
import { motion } from "framer-motion";
import Scroll from "../../components/locomotiveScroll";
import { fade } from "../../helpers/transitionHelper";
import { injectIntl, Link } from "gatsby-plugin-intl-v3";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import SEO from "../../components/seo";
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ApplyPage = ({ intl, location, data }) => {
  const color = "white";
  let colorBorderClasses = `border-offwhite `;

  if (color === "white") {
    colorBorderClasses = `border-offblack `;
  }


  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }, children) => (
          <Link to={data.uri}>{children}</Link>
      )
    },
  };


  return (
    <>
      <Scroll callback={location} />
      <SEO
          title={intl.formatMessage({ id: "applicTitle" })}
      />
      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div className="grid pt-16 md:grid-cols-4 md:pt-16 xl:pt-20">
          <div
            className="absolute top-0 right-0 bottom-0 left-0"
            id="pinned-pane"
          />
          <motion.div
            variants={fade}
            className="pb-10 overflow-hidden relative px-4 pt-5 md:col-span-1 md:h-screen md:py-24 md:mt-2 xl:mt-5 md:mb-0"
            data-scroll-sticky
            data-scroll
            data-scroll-target="#pinned-pane"
          >
            <h1 className="ml-3 md:ml-0 relative mb-0 pb-0 mt-12 md:mt-0 xl:mt-0 about-title md:w-10/12 xl:w-8/12 xl:pr-0 textreveal">
              {intl.formatMessage({ id: "applicTitle" })}
            </h1>

            <div className="mt-6 md:mt-20 ml-3 md:ml-0 scrollreveal">
              <Link
                to="/academy/"
                className={
                  colorBorderClasses +
                  `textreveal text-base md:text-lg xl:text-xl uppercase text-center inline-block mx-auto font-medium border-b-2 group`
                }
              >
                <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                  <span className="block transition duration-300 ease-in-out transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 md:-mt-px md:leading-tight">
                    <span className="block transform translate">
                      {intl.formatMessage({ id: "backAcademy" })}
                    </span>
                    <span className="hidden md:block">
                      {intl.formatMessage({ id: "backAcademy" })}
                    </span>
                  </span>
                </span>
              </Link>
            </div>
          </motion.div>

          <motion.div
            variants={fade}
            className="relative border-t border-black md:col-span-3 md:border-l md:border-t-0"
          >
            <div className="relative content" id="content-pane">
              <div className="h-full">
                <div className="z-30 mb-12 md:mb-16 xl:mb-24">
                  <div className="hidden md:block h-screen relative z-40 pointer-events-none" data-scroll-sticky data-scroll data-scroll-target="#scroll-container">
                    <div className="w-full h-64 hidden md:block absolute bottom-0 left-0 right-0 z-40"/>
                  </div>

                  <div className="mt-0 md:-mt-100vh md:p-12 xl:p-16 relative z-30">
                    <div className="px-4 my-12 md:my-16 xl:my-24 md:px-0 pt-12">

                      <div className="relative z-30">
                        <div className="lg:flex lg:flex-wrap items-start content">
                          <div className="w-full md:w-9/12">

                            <div className="scrollreveal w-full border-b border-black">
                              <div className="content pt-3 lg:pt-0">
                                {renderRichText(data.application.requirements, options)}
                              </div>
                            </div>

                            <div className="scrollreveal w-full border-b border-black">
                              <div className="text-justify content pt-3 md:pt-6">
                                <br/>
                                {renderRichText(data.application.description)}
                              </div>
                            </div>

                            <div className="scrollreveal w-full border-b border-black">
                              <div className="content pt-3 md:pt-6">
                                <br/>
                                {renderRichText(
                                  data.application.bankInformation
                                )}
                              </div>
                            </div>

                            <div className="scrollreveal w-full border-b border-black">
                              <div className="content pt-3 md:pt-6">
                                <br/>
                                {renderRichText(
                                  data.application.generalInformation
                                )}
                              </div>
                            </div>

                            <div className="scrollreveal w-full border-b border-black">
                              <div className="content pt-3 md:pt-6">
                                <br/>
                                {renderRichText(data.application.fees)}
                              </div>
                            </div>

                            <div className="scrollreveal w-full ">
                              <div className="content pt-3 md:pt-6 mb-6 md:mb-12 xl:mb-16">
                                <br/>
                                <br/>
                                
                                {renderRichText(data.application.contact)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className=""
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="relative z-50 -mt-5">
            <Footer />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default injectIntl(ApplyPage);

export const query = graphql`
  query ($language: String) {
    application: contentfulApplicationForm(
      contentful_id: { eq: "72tQtHs2tOhxJK2zZEiAbz" }
      node_locale: { eq: $language }
    ) {
      title
      requirements {
        raw
      }
      description {
        raw
      }
      bankInformation {
        raw
      }
      generalInformation {
        raw
      }
      fees {
        raw
      }
      contact {
        raw
      }
    }
  }
`;
