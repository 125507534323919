import React from "react";
import Footer from "../components/footer/footer";
import { motion } from "framer-motion";
import Scroll from "../components/locomotiveScroll";
import { fade } from "../helpers/transitionHelper";
import { injectIntl } from "gatsby-plugin-intl-v3";
import {graphql} from "gatsby";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import SEO from "../components/seo";

const PrivacyPage = ({ intl, location,data }) => {
  return (
    <>
      <Scroll callback={location} />
      <SEO
          title={intl.formatMessage({ id: "privacyPol" })}
      />
      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div className="grid md:grid-cols-3 pt-16 md:pt-16 xl:pt-20">
          {/* The Pinned area - BUGFIX */}
          <div
            className="absolute top-0 left-0 right-0 bottom-0"
            id="pinned-pane"
          />

          <motion.div
            variants={fade}
            className="md:col-span-1 md:h-screen relative px-4 py-6 md:py-24 md:px-12 md:mt-2 xl:mt-5 overflow-hidden"
            data-scroll-sticky
            data-scroll
            data-scroll-target="#pinned-pane"
          >
            <h1 className="mb-0 pb-0 md:-mt-12 xl:-mt-16 relative about-title md:w-12/12 xl:w-12/12 xl:pr-0 textreveal project-title">
              {intl.formatMessage({ id: "privacyPol" })}
            </h1>
          </motion.div>

          <motion.div
            variants={fade}
            className="md:col-span-2 bg-offwhitelight texture-overlay texture-overlay--dark text-offblack relative"
          >
            <div className="content relative" id="content-pane">
              <div className="h-full">
                {/* z-30 here */}

                <div className="mb-12 md:mb-24 xl:mb-32">
                  <div className="p-4 md:p-12 xl:p-16 md:pt-32 xl:pt-40 relative z-30">
                    <div className="lg:flex lg:flex-wrap items-start content">
                      <div className="w-11/12 md:w-9/12 lg:w-8/12 xl:w-8/12">
                        {renderRichText(data.privacy.text)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className=""
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="-mt-5 relative z-50">
            <Footer />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default injectIntl(PrivacyPage);

export const query = graphql`
  query ($language: String) {
    privacy: contentfulPrivacy(
      contentful_id: { eq: "65znaigP3BanpfRfXcc2MX" }
      node_locale: { eq: $language }
    ) {
      text {
        raw
      }
    }
  }
`;
