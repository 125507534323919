import React from "react";
import Footer from "../../components/footer/footer";
import { motion } from "framer-motion";
import Scroll from "../../components/locomotiveScroll";
import { fade } from "../../helpers/transitionHelper";
import { injectIntl, Link } from "gatsby-plugin-intl-v3";
import { graphql } from "gatsby";
import SEO from "../../components/seo";
import {GatsbyImage, getSrc} from "gatsby-plugin-image";
import {renderRichText} from "gatsby-source-contentful/rich-text";

const ConcertsPage = ({ intl, location, data }) => {
  const color = "white";
  let colorClasses = `bg-black text-offwhite `;
  let colorBorderClasses = `border-offwhite `;

  if (color === "white") {
    colorClasses = `bg-offwhite text-offblack `;
    colorBorderClasses = `border-offblack `;
  }
  return (
    <>
      <Scroll callback={location} />
      <SEO
          title={intl.formatMessage({ id: "concertsYear" })}
      />
      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div className="grid md:grid-cols-3 pt-16 md:pt-16 xl:pt-20">
          <div
            className="absolute top-0 left-0 right-0 bottom-0"
            id="pinned-pane"
          />

          <motion.div
            variants={fade}
            className=" pb-10 md:col-span-1 border-b md:border-b-0 md:border-r border-black md:h-screen relative px-4 py-6 md:py-24 md:px-12 overflow-hidden"
            data-scroll-sticky
            data-scroll
            data-scroll-target="#pinned-pane"
          >
            <h1 className="ml-3 md:ml-0 mb-0 pb-0 mt-12 md:mt-0 xl:mt-0 relative about-title md:w-12/12 xl:w-12/12 xl:pr-0 textreveal project-title">
              {intl.formatMessage({ id: "concertsYear" })}
            </h1>
            <div className="scrollreveal ml-3 md:ml-0 mt-6 md:mt-20">
              <Link
                to="/festival/"
                className={
                  colorBorderClasses +
                  `textreveal text-base md:text-lg xl:text-xl uppercase text-center inline-block mx-auto font-medium border-b-2 group`
                }
              >
                <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                  <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                    <span className="block transform translate">
                      {intl.formatMessage({ id: "backFest" })}
                    </span>
                    <span className="hidden md:block">
                      {intl.formatMessage({ id: "backFest" })}
                    </span>
                  </span>
                </span>
              </Link>
            </div>
          </motion.div>

          <motion.div
            variants={fade}
            className="md:col-span-2 bg-offwhitelight texture-overlay texture-overlay--dark text-offblack relative"
          >
            <div className="content relative" id="content-pane">
              <div className="h-full">
                <div className="mb-12 md:mb-24 xl:mb-32">
                  <div className="hidden md:block h-screen relative z-40 pointer-events-none" data-scroll-sticky data-scroll data-scroll-target="#scroll-container">
                    <div className="w-full h-64 hidden md:block absolute bottom-0 left-0 right-0 z-40"/>
                  </div>

                  <div className="mt-0 md:-mt-100vh md:p-12 xl:p-16 relative z-30">

                    <div className="px-9 md:px-0 lg:flex lg:flex-wrap items-start content">
                      <div className="pt-12 h-full w-full md:w-10/12">
                        {data.concerts.edges.map(({ node }, i) => {
                          return (
                            <motion.div
                              varians={fade}
                              data-scroll
                              key={i}
                              className="pt-3 w-full mb-6 md:mb-12 xl:mb-16"
                            >
                              <h4 className="mx-3 mb-0 md:mb-3 pb-0">
                                <span className="overflow-hidden block scrollreveal">
                                  <span className="block">{node.artists}</span>
                                </span>
                              </h4>

                              {node.where && (
                                <span className="mx-3 md:mx-0 overflow-hidden block scrollreveal border-t border-black">
                                  <span className="block">
                                    <span className="text-xs md:text-sm">
                                      {node.where}
                                    </span>
                                  </span>
                                </span>
                              )}
                              <span className="mx-3 md:mx-0 overflow-hidden block scrollreveal">
                                <span className="block">
                                  <span className="text-xs md:text-sm italic">
                                    {node.concertDetails} | {node.date}
                                  </span>
                                </span>
                              </span>
                              <span className="mx-0 overflow-hidden block scrollreveal">
                                <span className="block">
                                  <span className="text-xs md:text-sm italic">
                                    {renderRichText(node.program)}
                                  </span>
                                </span>
                              </span>

                              <span className="mx-0 overflow-hidden block scrollreveal">
                                <span className="block">
                                  <a className="text-xs md:text-sm italic"
                                     href={node.slug}
                                     target="_blank"
                                  >
                                    {intl.formatMessage({ id: "concertBooklet" })}
                                  </a>
                                </span>
                              </span>

                              <div className="order-2 md:order-1 md:grid-col-1">
                                <div
                                    className={`w-full`}
                                >
                                  <div className="scrollreveal">
                                    <div className="w-full">

                                            <div
                                                className="w-full h-full my-8"
                                            >
                                              <GatsbyImage
                                                  image={node.image.gatsbyImageData}
                                                  alt={node.image.title}
                                                  objectFit="contain"
                                                  className="w-full h-full"
                                              />
                                            </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </motion.div>
                          );
                        })}
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className=""
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="-mt-5 relative z-50">
            <Footer />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default injectIntl(ConcertsPage);

export const query = graphql`
  query ($language: String) {
    concerts: allContentfulConcerts(
      sort: { fields: date, order: ASC }
      filter: { node_locale: { eq: $language } }
    ) {
      edges {
        node {
          program {
            raw
          }
          artists
          slug
          concertDetails
          where
          date(formatString: "DD.MM.YYYY")
          image {
            title
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
