import React, { Component } from "react";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import { injectIntl, Link } from "gatsby-plugin-intl-v3";

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      fade: true,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="md:mb-6 md:mt-16 w-full md:w-10/12 h-84 md:h-carouselL flex flex-col">
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {this.props.images.map((image, i) => {
              return (
                <div
                  key={i}
                  className="w-full h-full box-border border-black border-2 md:border-8 bg-offwhite"
                >
                  <figure className="w-full h-full relative text-center">
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.title}
                      objectFit="cover"
                      objectPosition="center"
                      className=" h-full w-full max-w-full overflow-hidden"
                    />
                    {image.description && (
                      <figcaption className="text-xs md:text-lg z-50 absolute bottom-0 left-0 w-full text-center p-2 md:p-3 mix-blend-difference text-offwhite">
                        {image.description}
                      </figcaption>
                    )}
                  </figure>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="w-full md:w-3/5 flex flex-wrap items-center mt-6 md:mt-0 mb-0 md:mb-12">
          <div className="flex flex-wrap items-center w-full px-0">
            <div className="w-full mx-auto border-none border-black h-full pb-0 md:pb-4">
              <div className="flex flex-wrap items-center">
                <div className="flex justify-between items-center flex-row w-full">
                  <button
                    onClick={this.previous}
                    className="focus:outline-none hover:outline-none flex items-center justify-center w-8 md:w-12 h-8 md:h-12 rounded-full border border-black leading-none text-2xl mx-1 transform group"
                  >
                    <span className="block overflow-hidden relative h-auto md:h-4 xl:h-4">
                      <span className="block transform md:group-hover:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-1 md:leading-tight">
                        <span className="block transform translate pt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-3"
                            viewBox="0 0 16.01 18.144"
                          >
                            <path
                              d="M0 6.404l6.4-6.4 1.52 1.511-3.832 3.821H16.01v12.808h-2.135V7.471H4.088l3.832 3.821-1.516 1.516z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <span className="hidden md:block pt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-3"
                            viewBox="0 0 16.01 18.144"
                          >
                            <path
                              d="M0 6.404l6.4-6.4 1.52 1.511-3.832 3.821H16.01v12.808h-2.135V7.471H4.088l3.832 3.821-1.516 1.516z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                  </button>

                  <div className="mx-auto text-center mt-3 mb-0 md:mb-2">
                    <Link
                      to={this.props.to}
                      className="text-base md:text-lg xl:text-xl uppercase leading-none font-medium block self-start text-left pl-2 group"
                    >
                      <span className="inline-block transform rotate-180 leading-none align-top mr-1">
                        ↳
                      </span>
                      <span className="inline-block overflow-hidden relative h-auto md:h-5 xl:h-5 leading-tight align-top">
                        <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                          <span className="block transform translate">
                            {this.props.intl.formatMessage({
                              id: "backButton",
                            })}
                          </span>
                          <span className="hidden md:block">
                            {this.props.intl.formatMessage({
                              id: "backButton",
                            })}
                          </span>
                        </span>
                      </span>
                    </Link>
                  </div>

                  <button
                    onClick={this.next}
                    className="flex items-center justify-center w-8 md:w-12 h-8 md:h-12 rounded-full border border-black leading-none text-2xl mx-1 transform group focus:outline-none hover:outline-none"
                  >
                    <span className="block overflow-hidden relative h-auto md:h-4 xl:h-4">
                      <span className="block transform md:group-hover:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-1 md:leading-tight">
                        <span className="block transform translate pt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-3"
                            viewBox="0 0 16.01 18.144"
                          >
                            <path
                              d="M16.01 6.4L9.606 0 8.09 1.516l3.832 3.821H0v12.807h2.135V7.471h9.787L8.09 11.292l1.516 1.516z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <span className="hidden md:block pt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-3"
                            viewBox="0 0 16.01 18.144"
                          >
                            <path
                              d="M16.01 6.4L9.606 0 8.09 1.516l3.832 3.821H0v12.807h2.135V7.471h9.787L8.09 11.292l1.516 1.516z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(Carousel)
