import React from "react";
import FooterCta from "../footerCta/footerCta";
import { injectIntl } from "gatsby-plugin-intl-v3";
import { Link } from "gatsby-plugin-intl-v3";
const Footer = ({ color, hideCta, intl }) => {
  let colorClasses = `bg-black text-offwhite texture-overlay texture-overlay--dark `;
  let colorBorderClasses = `border-offwhite `;

  if (color === "white") {
    colorClasses = `bg-offwhite text-offblack texture-overlay `;
    colorBorderClasses = `border-black `;
  }

  return (
    <>
      {!hideCta ? <FooterCta color={color} /> : <></>}

      <footer
        className={colorClasses + colorBorderClasses + `border-t w-full `}
      >
        <div className="flex flex-wrap items-center">
          <a
            href="https://instagram.com/academyfestivalistanbul/"
            target="_blank"
            rel="noopener noreferrer"
            className={
              colorBorderClasses +
              `text-xs md:text-lg xl:text-xl uppercase font-medium block px-3 md:px-6 xl:px-8 py-4 md:py-5 xl:py-6 border-r group`
            }
          >
            <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
              <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                <span className="block transform translate">
                  Insta<span className="hidden md:inline">gram</span>
                </span>
                <span className="hidden md:block">
                  Insta<span className="hidden md:inline">gram</span>
                </span>
              </span>
            </span>
          </a>

          <span
            className={
              colorBorderClasses +
              `text-xs md:text-lg xl:text-xl font-medium px-3 md:px-6 xl:px-8 py-4 md:py-5 xl:py-6 border-r flex items-center`
            }
          >
            <span className="mr-1 uppercase">
              {intl.formatMessage({ id: "design" })}
            </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="group leading-none flex"
              href="https://locksbridge.net/"
            >
              <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px xl:pt-px md:leading-tight">
                  <span className="block transform translate">LocksBridge</span>
                  <span className="hidden md:block">LocksBridge</span>
                </span>
              </span>
            </a>
          </span>
          <Link
            className={
              colorBorderClasses +
              `text-xs md:text-lg xl:text-xl uppercase font-medium block px-3 md:px-6 xl:px-8 py-4 md:py-5 xl:py-6 ml-auto border-l group`
            }
            to="/privacy"
          >
            <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
              <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                <span className="block transform translate">
                  {intl.formatMessage({ id: "privacy" })}
                </span>
                <span className="hidden md:block">
                  {intl.formatMessage({ id: "privacy" })}
                </span>
              </span>
            </span>
          </Link>
          <span
            className={
              colorBorderClasses +
              `text-xs md:text-lg xl:text-xl uppercase font-medium block px-3 md:px-6 xl:px-8 py-4 md:py-5 xl:py-6 border-l`
            }
          >
            &copy;2022
          </span>
        </div>
      </footer>
    </>
  );
};

export default injectIntl(Footer);
