import React from "react";
import Footer from "../components/footer/footer";
import { graphql } from "gatsby";
import { motion } from "framer-motion";
import Scroll from "../components/locomotiveScroll";
import { fade } from "../helpers/transitionHelper";
import { injectIntl, Link } from "gatsby-plugin-intl-v3";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import SEO from "../components/seo";

const AcademyPage = ({ intl, location, data }) => {
  const color = "white";
  let colorClasses = `bg-black text-offwhite `;
  let colorBorderClasses = `border-offwhite `;

  if (color === "white") {
    colorClasses = `bg-offwhite text-offblack `;
    colorBorderClasses = `border-offblack `;
  }
  return (
    <>
      <SEO
          title={intl.formatMessage({ id: "academy" })}
      />
      <Scroll callback={location} />
      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div className="grid md:grid-cols-4 pt-16 md:pt-16 xl:pt-20">
          <div
            className="absolute top-0 left-0 right-0 bottom-0"
            id="pinned-pane"
          />
          <motion.div
            variants={fade}
            className="ml-3 md:ml-0 md:col-span-1 md:h-screen relative px-4 pt-5 pb-10 md:py-24 md:mt-2 xl:mt-5 overflow-hidden md:mb-0"
            data-scroll-sticky
            data-scroll
            data-scroll-target="#pinned-pane"
          >
            <h1 className="mb-0 pb-0 mt-12 md:mt-0 xl:mt-0 relative about-title md:w-10/12 xl:w-8/12 xl:pr-0 textreveal">
              {intl.formatMessage({ id: "academy" })}
            </h1>

            <div className="scrollreveal  mt-6 md:mt-8">
              <Link
                to="/academy/faculty/"
                className={
                  colorBorderClasses +
                  `textreveal text-base md:text-lg xl:text-xl uppercase text-center inline-block mx-auto font-medium border-b-2 group`
                }
              >
                <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                  <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                    <span className="block transform translate">
                      {intl.formatMessage({ id: "facYear" })}
                    </span>
                    <span className="hidden md:block">
                      {intl.formatMessage({ id: "facYear" })}
                    </span>
                  </span>
                </span>
              </Link>
            </div>

            <div className="scrollreveal mt-5">
              <Link
                to="/academy/application/"
                className={
                  colorBorderClasses +
                  `textreveal text-base md:text-lg xl:text-xl uppercase text-center inline-block mx-auto font-medium border-b-2 group`
                }
              >
                <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                  <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                    <span className="block transform translate">
                      {intl.formatMessage({ id: "applicTitle" })}
                    </span>
                    <span className="hidden md:block">
                      {intl.formatMessage({ id: "applicTitle" })}
                    </span>
                  </span>
                </span>
              </Link>
            </div>

            <div className="scrollreveal mt-5">
              <Link
                  to="/academy/management/"
                  className={
                    colorBorderClasses +
                    `textreveal text-base md:text-lg xl:text-xl uppercase text-center inline-block mx-auto font-medium border-b-2 group`
                  }
              >
                <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                  <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                    <span className="block transform translate">{intl.formatMessage({ id: "management" })}</span>
                    <span className="hidden md:block">
                      {intl.formatMessage({ id: "management" })}
                    </span>
                  </span>
                </span>
              </Link>
            </div>
          </motion.div>

          <motion.div
            variants={fade}
            className="md:col-span-3 border-t md:border-l md:border-t-0 border-black bg-black texture-overlay texture-overlay--dark text-white relative"
          >
            <div className="content relative" id="content-pane">
              <div className="h-full">
                <div className="mb-12 md:mb-16 xl:mb-24 z-30">
                  <div
                    className="hidden h-0 relative z-40 pointer-events-none"
                    data-scroll
                  >
                    <div className="w-full h-56 hidden from-black via-black to-transparent absolute bottom-0 left-0 right-0 bg-gradient-to-t z-40" />
                  </div>
                  <div className="md:p-12 xl:p-16 relative z-30">
                    <div className="lg:flex lg:flex-wrap items-start px-4 md:px-0 pt-12">
                      <div className="scrollreveal">
                        <h2 className="text-offwhite w-10/12 mx-3 md:ml-0 mb-12 text-left md:mb-16 xl:mb-24 lg:pt-2">
                          {data.academy.title}
                        </h2>
                      </div>
                      <div className=" scrollreveal w-full md:w-9/12">
                        <div className="content pt-3 lg:pt-0 text-offwhite">
                          {renderRichText(data.academy.description)}
                          <br/>
                          <br/>

                          {renderRichText(data.academy.program)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className=""
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="-mt-5 relative z-50">
            <Footer />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default injectIntl(AcademyPage);

export const query = graphql`
  query ($language: String) {
    academy: contentfulAbout(
      contentful_id: { eq: "6Jm20BmJrwfEMO3Ak6SjXO" }
      node_locale: { eq: $language }
    ) {
      title
      description {
        raw
      }
      program {
        raw
      }
    }
  }
`;
