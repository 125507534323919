import React from "react";
import Footer from "../components/footer/footer";
import { graphql } from "gatsby";
import { motion } from "framer-motion";
import Scroll from "../components/locomotiveScroll";
import { fade } from "../helpers/transitionHelper";
import { GatsbyImage } from "gatsby-plugin-image";
import { injectIntl } from "gatsby-plugin-intl-v3";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import CarouselIndex from "../components/carouselndex";
import CarouselConcerts from "../components/CarouselConcerts";

import SEO from "../components/seo";

const IndexPage = ({ intl, data, location }) => {
  return (
    <>
      <SEO
          title={intl.formatMessage({ id: "title" })}
          description={intl.formatMessage({ id: "descSeo" })}
      />
      <Scroll callback={location} />

      <motion.section
        className="bg-offwhitelight"
        initial="initial"
        animate="enter"
        exit="exit"
      >
        <div className="overflow-hidden">
          <div className="pt-16 md:pt-12 xl:pt-16">

            {/*LANDING*/}
            <motion.div
              variants={fade}
              className="mt-5 md:-mt-4 xl:-mt-8 relative z-0"
            >
              {/*
              <div className="pb-0 md:pb-20 3xl:pb-32 bg-offwhitelight">
                <div className="relative lg:-mt-12">
                  <motion.div
                      variants={fade}
                      className="mx-auto px-4 md:px-0 py-20 md:py-10 xl:py-12"
                  >
                    <CarouselConcerts images={data.concerts.images} />
                  </motion.div>
                </div>
              </div>
*/}

              <div className="pt-20 pb-0 md:pb-20 3xl:pb-32 bg-offwhitelight">
                <div className="relative lg:-mt-12">
                  <motion.div
                      variants={fade}
                      className="w-full md:px-10 px-3 py-6 md:py-10 xl:py-12"
                  >
                    <CarouselIndex images={data.artists.edges} />
                  </motion.div>
                </div>
              </div>


              <div className="flex flex-wrap py-4 md:py-16 xl:py-20 px-4 md:px-12 lg:px-16 xl:px-20 scrollreveal">
                <div className="w-full md:w-1/2">
                  <span className="text-base text-center md:text-left md:text-lg xl:text-xl uppercase font-medium block">
                    <span className="inline-block transform rotate-90 -ml-1">
                      ↳
                    </span>{" "}
                    {intl.formatMessage({ id: "whoWeAre" })}
                  </span>
                </div>
              </div>
            </motion.div>

            {/*BELOW HEADER*/}
            <motion.div variants={fade} className="bg-offwhite">
              <div className="">
                <div className="grid md:grid-cols-2 border-b border-black">
                  <div className="md:grid-col-1 border-b md:border-b-0 md:border-r border-offblack">
                    <div className={`w-full p-4 pt-8 md:p-12 lg:p-16 xl:p-20 `}>
                      <div className="scrollreveal">
                        <h3 className="text-center md:text-left flex flex-wrap items-center w-full mb-6 md:mb-8">
                          <span className="inline-block">
                            {data.fest.title}
                          </span>
                        </h3>
                        <div className="text-justify w-full content md:w-11/12 xl:w-10/12 xl:max-w-2xl">
                          {renderRichText(data.fest.description)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:grid-col-1">
                    <div className={`w-full p-4 pt-8 md:p-12 lg:p-16 xl:p-20`}>
                      <div className="scrollreveal">
                        <h3 className="text-center md:text-left flex flex-wrap md:justify-start justify-center items-center w-full mb-6 md:mb-8">
                          <span className="inline-block">
                            {data.academy.title}
                          </span>
                        </h3>
                        <div className="text-justify w-full content md:w-11/12 xl:w-10/12 xl:max-w-2xl">
                          {renderRichText(data.academy.description)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            {/*SECOND HEADER*/}
            <div className="flex flex-wrap py-8 md:py-16 xl:py-20 px-4 md:px-12 lg:px-16 xl:px-20 scrollreveal">
              <div className="w-full md:w-1/2">
                <span className="text-base text-center md:text-left md:text-lg xl:text-xl uppercase font-medium block">
                  <span className="inline-block transform rotate-90 -ml-1">
                    ↳
                  </span>{" "}
                  {intl.formatMessage({ id: "festVenue" })}
                </span>
              </div>
            </div>
            {/*LOCATION*/}
            <motion.div variants={fade} className="bg-offwhite">
              <div className="">
                <div className="grid md:grid-cols-2 border-t border-black">
                  <div className="order-2 md:order-1 md:grid-col-1 md:border-r border-offblack">
                    <div
                      className={`w-full p-4 pt-8 md:p-12 lg:p-16 xl:p-20 border-b border-offblack md:border-b-0`}
                    >
                      <div className="scrollreveal">
                        <div className="w-full">
                          {data.location.images.map((image, i) => {
                            return (
                              <div
                                key={i}
                                className="w-full h-full mb-8 mx-auto"
                              >
                                <GatsbyImage
                                  image={image.gatsbyImageData}
                                  alt={image.title}
                                  objectFit="contain"
                                  className="w-full h-full"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="order-1 md:order-2 md:grid-col-1">
                    <div className={`w-full p-4 pt-8 md:p-12 lg:p-16 xl:p-20`}>
                      <div className="scrollreveal">
                        <h4 className="text-center md:text-left flex flex-wrap items-center w-full mb-6 md:mb-8">
                          <span className="inline-block about-title">
                            {data.location.subtitle}
                          </span>
                        </h4>

                        <h4 className="text-center md:text-left flex flex-wrap items-center w-full mb-6 md:mb-8">
                          <span className="inline-block font-bold">
                            {data.location.title}
                          </span>
                        </h4>
                        <div className="text-justify w-full content md:w-11/12 xl:w-10/12 xl:max-w-2xl">
                          {renderRichText(data.location.description)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>

          <motion.div variants={fade} className="mb-0">
            <Footer />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default injectIntl(IndexPage);

export const query = graphql`
  query ($language: String) {
    images: allImageSharp(
      filter: { original: { src: { regex: "/reform/" } } }
    ) {
      edges {
        node {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
    location: contentfulLocation(
      contentful_id: { eq: "2hgGiHzYMgrOs2poqFTFoW" }
      node_locale: { eq: $language }
    ) {
      title
      subtitle
      description {
        raw
      }
      images {
        title
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    fest: contentfulAbout(
      contentful_id: { eq: "4tNHqEOgHkc6APWG4JirAN" }
      node_locale: { eq: $language }
    ) {
      title
      description {
        raw
      }
    }
    academy: contentfulAbout(
      contentful_id: { eq: "6Jm20BmJrwfEMO3Ak6SjXO" }
      node_locale: { eq: $language }
    ) {
      title
      description {
        raw
      }
    }
    artists: allContentfulArtistsPages(
      filter: { node_locale: { eq: $language }, mainPage: { eq: true } }
      sort: { fields: priority, order: ASC }
    ) {
      edges {
        node {
          slug
          name
          subtitle
          images {
            title
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    concerts: contentfulPosters(
      contentful_id: { eq: "zI1t0nHzgx5ZNEd28tcxl" }
      node_locale: { eq: $language }
    ) {
      images {
        title
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
