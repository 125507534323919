import React from "react";
import Footer from "../../components/footer/footer";
import { motion } from "framer-motion";
import Scroll from "../../components/locomotiveScroll";
import { fade } from "../../helpers/transitionHelper";
import { injectIntl, Link } from "gatsby-plugin-intl-v3";
import { graphql } from "gatsby";
import {GatsbyImage, getSrc} from "gatsby-plugin-image";
import SEO from "../../components/seo";

const ArtistsPage = ({ intl, location, data }) => {
  const color = "white";
  let colorClasses = `bg-black text-offwhite `;
  let colorBorderClasses = `border-offwhite `;

  if (color === "white") {
    colorClasses = `bg-offwhite text-offblack `;
    colorBorderClasses = `border-offblack `;
  }
  return (
    <>
      <Scroll callback={location} />
      <SEO
          title={intl.formatMessage({ id: "artistsYear" })}
          image={getSrc(data.artists.edges[0].node.images[0])}
      />
      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div className="grid md:grid-cols-3 pt-16 md:pt-16 xl:pt-20">
          <div
            className="absolute top-0 left-0 right-0 bottom-0"
            id="pinned-pane"
          />

          <motion.div
            variants={fade}
            className="ml-3 md:ml-0 md:col-span-1 border-r border-black md:h-screen relative px-4 py-6 md:py-24 pb-10 md:px-12 overflow-hidden"
            data-scroll-sticky
            data-scroll
            data-scroll-target="#pinned-pane"
          >
            <h1 className="mb-0 pb-0 mt-12 md:mt-0 xl:mt-0 relative about-title md:w-12/12 xl:w-12/12 xl:pr-0 textreveal project-title">
              {intl.formatMessage({ id: "artistsYear" })}
            </h1>
            <div className="scrollreveal mt-6 md:mt-20">
              <Link
                to="/festival/"
                className={
                  colorBorderClasses +
                  `textreveal text-base md:text-lg xl:text-xl uppercase text-center inline-block mx-auto font-medium border-b-2 group`
                }
              >
                <span className="block overflow-hidden relative h-auto md:h-5 xl:h-6 md:my-3px">
                  <span className="block transform md:group-hover:-translate-y-1/2 md:group-focus:-translate-y-1/2 transition duration-300 ease-in-out md:-mt-px md:leading-tight">
                    <span className="block transform translate">
                      {intl.formatMessage({ id: "backFest" })}
                    </span>
                    <span className="hidden md:block">
                      {intl.formatMessage({ id: "backFest" })}
                    </span>
                  </span>
                </span>
              </Link>
            </div>
          </motion.div>

          <motion.div
            variants={fade}
            className="md:col-span-2 bg-black texture-overlay texture-overlay--dark text-offblack relative"
          >
            <div className="content relative" id="content-pane">
              <div className="h-full">
                <div className="mb-12 md:mb-24 xl:mb-32">
                  <div className="hidden md:block h-screen relative z-40 pointer-events-none" data-scroll-sticky data-scroll data-scroll-target="#scroll-container">
                    <div className="w-full h-64 hidden md:block absolute bottom-0 left-0 right-0 z-40"/>
                  </div>

                  <div className="mt-16 mb-20 md:-mt-100vh pt-0 md:pt-18 xl:pt-24 md:mb-32 xl:mb-40 md:col-span-3 bg-black texture-overlay texture-overlay--dark text-white relative">

                    <div className="w-full mb-6 md:mb-12 xl:mb-16 mx-auto">
                      <div className="scrollreveal">
                        <h2 className="text-offwhite mb-12 px-4 md:px-0 ml-3 md:ml-0 text-left md:text-center md:mb-16 xl:mb-24 lg:pt-2">
                          {intl.formatMessage({ id: "festArtists" })}
                        </h2>
                      </div>
                    </div>

                    {data.artists.edges.map(({ node }, i) => {
                      return (
                        <motion.div
                          varians={fade}
                          data-scroll
                          className="md:h-carouselX h-full w-10/12 md:w-1/2 mb-6 md:mb-12 xl:mb-16 mx-auto"
                          key={i}
                        >
                          <Link
                            className="block relative group about-image-teaser"
                            to={`/festival/artists/${node.slug}/`}
                          >
                            <div className="overflow-hidden mb-5 scrollreveal">
                              <div className="overflow-hidden grayimage gatsby-image-wrapper">
                                <GatsbyImage
                                  image={node.images[0].gatsbyImageData}
                                  alt={node.images[0].title}
                                  className="w-full h-auto mb-0 pb-0 block transform transition ease-in-out duration-1000 group-hover:scale-110 group-focus:scale-110 scale-105 will-change"                                />
                              </div>
                            </div>
                            <div className="pb-5 mb-5 relative px-4 md:px-0">
                              <span className="overflow-hidden block scrollreveal mb-1">
                                <span className=" block">
                                  <h4 className="text-offwhite mb-0 block text-center">
                                    {node.name}
                                  </h4>
                                </span>
                              </span>

                              <span className="overflow-hidden block scrollreveal">
                                <span className=" block">
                                  <span className="text-offwhite text-sm block italic text-center">
                                    {node.subtitle}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </Link>
                        </motion.div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className=""
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="-mt-5 relative z-50">
            <Footer />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export default injectIntl(ArtistsPage);

export const query = graphql`
  query ($language: String) {
    artists: allContentfulArtistsPages(
      filter: { node_locale: { eq: $language } }
      sort: { fields: priority, order: ASC }
    ) {
      edges {
        node {
          name
          slug
          subtitle
          images {
            title
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
