import React from "react";
import { injectIntl } from "gatsby-plugin-intl-v3";
import SEO from "../components/seo";

const NotFoundPage = ({ intl }) => (
  <>
      <SEO/>
    <div className="pt-28">
      <h1>{intl.formatMessage({ id: "notfound.header" })}</h1>
      <p>{intl.formatMessage({ id: "notfound.description" })}</p>
    </div>
  </>
);

export default injectIntl(NotFoundPage);
