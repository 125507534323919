import React from "react";
import Footer from "../components/footer/footer";
import { graphql } from "gatsby";
import { injectIntl, Link } from "gatsby-plugin-intl-v3";
import { motion } from "framer-motion";
import Scroll from "../components/locomotiveScroll";
import { fade } from "../helpers/transitionHelper";
import Carousel from "../components/carousel";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import SEO from "../components/seo";
import {getSrc} from "gatsby-plugin-image";

const ProjectAcademyPage = ({ location, intl, data }, props) => {
  const { name, about, subtitle, images } = data.contentfulArtistsPages;

  return (
    <>
      <Scroll callback={props.location} />
      <SEO
          title={`${name} – ${subtitle}`}
          image={getSrc(images[0])}
      />
      <motion.section
        initial="initial"
        className="bg-offwhitelight overflow-hidden"
        animate="enter"
        exit="exit"
      >
        <div data-scroll>
          <div className="bg-offwhitelight border-b border-offblack">
            <div className="relative mt-20 md:mt-8 lg:mt-12">
              <motion.div
                variants={fade}
                className="w-full md:px-20 px-4 py-6 md:py-10 xl:py-12"
              >
                <Carousel
                  images={images}
                  mobileImage={images}
                  location={subtitle}
                  title={name}
                  to="/academy/faculty/"
                />
              </motion.div>
            </div>
          </div>
          <motion.div variants={fade} className="w-full z-30 pt-0">
            <div className="pb-16 md:pb-24 relative flex flex-wrap w-full">
              <div className="px-4 md:px-0 w-full md:mx-60 pt-10">
                <h1 className="font-bold mx-3 md:mx-0 w-full project-title mb-8 md:mb-12 block textreveal">
                  {name} |{" "}
                  <span className="italic font-normal">{subtitle}</span>
                </h1>

                <div className="content text-justify w-full">
                  {renderRichText(about)}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        initial="initial"
        className="bg-offwhitelight"
        animate="enter"
        exit="exit"
      >
        <div>
          <motion.div variants={fade} className="-mt-5 relative z-50">
            <Footer hideCta color="white" />
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

export const query = graphql`
  query ContentFulArtistAcademyPage($slug: String, $language: String) {
    contentfulArtistsPages(slug: { eq: $slug }, node_locale: { eq: $language }) {
      slug
      node_locale
      name
      subtitle
      about {
        raw
      }
      images {
        title
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export default injectIntl(ProjectAcademyPage);
